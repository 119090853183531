import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {  Link } from 'react-router-dom';
import config from '../config';
import { Loader } from './child/Loader';

const AssignRoleLayer = () => {
    const [datas, setDatas] = useState()
    const [loading, setLoading] = useState(false); // Loading state
    // const [message, setMessage] = useState()
    const [role, SetRole] = useState()
    const [idUser,setId] = useState()
    const [totalPages , setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const individual = useSelector((state)=>  state?.Individual?.individual_role)
    const id = useSelector((state)=> state?.user?.currentUser?._id)

    async function fetchData(){
        const token = localStorage.getItem('access_token')
        setLoading(true)
        const data = await fetch(`${config.apiUrl}Access_data?&page=${currentPage}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
       if(data.ok){
        setLoading(false)
       }

        
        const json_data = await data.json()
        setDatas(json_data.user)
        setTotalPages(Number(json_data.totalPages))
        

    }
    const PAGE_LIMIT = 5; // Number of pages to show before and after current page

    const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
    };
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
    // const handleFile = async (e)=>{
    //     let Respones = await fetch(`${config.apiUrl}mapped_collection?excel=true`)
    //     const json_data = await Respones.json()

    //     const binaryString = window.atob(json_data.body);  
    //     const byteArray = new Uint8Array(binaryString.length); 
    //     for (let i = 0; i < binaryString.length; i++) {
    //       byteArray[i] = binaryString.charCodeAt(i);
    //     }
    
    //     const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
    //     const url = window.URL.createObjectURL(blob);
    
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'loan_mapping.xlsx'; 
    //     link.click();
    
    //     window.URL.revokeObjectURL(url);



    // }
    const handlechange = async(e)=>{
        SetRole(e.target.value)


    }
    const handleClick = (e)=>{
        setId(e._id)
    }
    const onSubmit = async(e)=>{
        let json_role  = (JSON.parse(role))
            // const update_date = await fetch(`${config.apiUrl}user_role/${idUser}`,{
            //     method: 'PATCH',
            //     headers: {
            //         "Content-Type": "application/json",
            //       },
              
            //     body: JSON.stringify({
            //         id: id,
            //         role_name: json_role.role
    
            //     })
    
            // })

    }
  
    useEffect(()=>{
        fetchData()
    },[role])

  return (
  <>
  <Loader loading={loading}/>
    <div className="card h-100 p-0 radius-12">
    <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex align-items-center flex-wrap gap-3">
            <span className="text-md fw-medium text-secondary-light mb-0">Show</span>
           
            <form className="navbar-search">
                <input
                    type="text"
                    className="bg-base h-40-px w-auto"
                    name="search"
                    placeholder="Search"
                />
                <Icon icon="ion:search-outline" className="icon" />
            </form>
            <select
                className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px"
                defaultValue="Status"
            >
                <option value="Status" disabled>
                    Status
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select>
        </div>
        <div className="d-flex flex-wrap align-items-center gap-3">
            

        {/* {individual?.assign_role?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)} */}

</div>
    </div>
    <div className="card-body p-24">
                    <div className="table-responsive scroll-sm">
                        <table className="table bordered-table sm-table mb-0">
                            <thead>
                                <tr>

                                    <th scope="col">User Name</th>
                                    <th scope="col">Role & Permission </th>

                                    <th scope="col">Status</th>
                                    <th scope="col">Login Status</th>


                                    <th scope="col" className="text-center">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {datas?.map((data, index) => (
                                    <tr key={index}>

                                        <td>{data?.username}</td>
                                        <td>{data?.role}</td>

                                        <td>
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data.status
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.status ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data.isloggedIn
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.isloggedIn ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <div className="d-flex align-items-center gap-10 justify-content-center">
                                                {individual?.assign_role?.readwrite == true && (
                                                <button
                                                    type="button"
                                                    className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                >
                                                    <Icon data-bs-toggle="modal"
                                                        data-bs-target="#Modal1" onClick={() => handleClick(data)} icon="lucide:edit" className="menu-icon" />
                                                </button>
                                                  
                                                  )}
                                                                                                  {individual?.assign_role?.readwrite == true && (


                                                <button
                                                    type="button"
                                                    className="remove-item-btn bg-danger-focus bg-hover-danger-200 text-danger-600 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                                                >
                                                    <Icon
                                                        icon="fluent:delete-24-regular"
                                                        className="menu-icon"
                                                    />
                                                </button>
                                                                                                  )}

                                            </div>
                                        </td>
                                    </tr>

                                ))}

                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap gap-2 mt-24">
                    <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>                        <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
             {/* Previous Button */}
             <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
                        </ul>
                    </div>
                </div>
</div>

{/* modal start */}

<div
                className="modal fade"
                id="Modal1"
                tabIndex={-1}
                aria-labelledby="ModalLabel1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
                    <div className="modal-content radius-16 bg-base">
                        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
                            <h1 className="modal-title fs-5" id="ModalLabel1">
                                Change Role
                            </h1>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body p-24">
                            <form action="#">
                                <div className="row">
                                    <div className="col-12 mb-20">
                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Role Name
                                        </label>
                                        <select className="form-control radius-8" defaultValue= "Select Role"onChange={handlechange}>
  <option value="Select Role" disabled>
                                        Select Role
                                    </option>
                                    <option value="SuperAdmin">Super Admin</option>
                                    <option value="admin">Admin</option>
                                    <option value="Agencies">Agencies</option>
                                    <option value="Agent">Agent</option>

                                    <option value="StucredAgencies">Stucred Agencies</option>
                                    <option value="StucredAgent">Stucred Agent</option>

</select>
                          </div>

                                    <div className="col-12 mb-20">
                                        <label className="form-label fw-semibold text-primary-light text-sm mb-8">
                                            Status{" "}
                                        </label>
                                        <div className="d-flex align-items-center flex-wrap gap-28">
                                            <div className="form-check checked-success d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="label"
                                                    id="Personal"
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                                    htmlFor="Personal"
                                                >
                                                    <span className="w-8-px h-8-px bg-success-600 rounded-circle" />
                                                    Active
                                                </label>
                                            </div>
                                            <div className="form-check checked-danger d-flex align-items-center gap-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="label"
                                                    id="Holiday"
                                                />
                                                <label
                                                    className="form-check-label line-height-1 fw-medium text-secondary-light text-sm d-flex align-items-center gap-1"
                                                    htmlFor="Holiday"
                                                >
                                                    <span className="w-8-px h-8-px bg-danger-600 rounded-circle" />
                                                    Inactive
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                                        <button
                                            type="reset"
                                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                                                 data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                                            onClick={onSubmit}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
</>
  )
}

export default AssignRoleLayer