import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import UnitCountFour from '../components/child/UnitCountFour'
import UnitCountFour2 from '../components/child/UnitCountFour2'
import EarningStaticOne from '../components/child/EarningStaticOne'
import UserActivatesTwo from '../components/child/UserActivatesTwo'
import Breadcrumb from '../components/Breadcrumb'
import DoubleChartCollect1 from '../components/child/DoubleChartCollect1'

const Inhousedashboard = () => {
  return (
    <MasterLayout  >
        <Breadcrumb title="Over All Dashboard" />

                    <div className="row gy-4">

                        <UnitCountFour />
                        <UnitCountFour2 />

                        {/* <EarningStaticOne/> */}
                        {/* <UserActivatesTwo/> */}
                        {/* <DoubleChartCollect1/> */}
                        </div>

</MasterLayout>

  )
}

export default Inhousedashboard