import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import config from '../../config';

const UnitCountOne = () => {
    const [loanData, setLoanData] = useState()
    async function fetchData() {
        const token = localStorage.getItem('access_token')
        const Response = await fetch(`${config.apiUrl}agencysum`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const data = await Response.json()
        setLoanData(data.result[0])

    }
    useEffect(()=>{
        fetchData()
    },[])
  return (
    <div className="row row-cols-xxxl-5 row-cols-lg-3 row-cols-sm-2 row-cols-1 gy-4">
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-1 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">Total Loan Assigned</p>
                        <h6 className="mb-0">{loanData?.totalSum}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-cyan rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="gridicons:multiple-users"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
      
            </div>
        </div>
        {/* card end */}
    </div>
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-2 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">
                            Total Loan Number
                        </p>
                        <h6 className="mb-0">{loanData?.totalCount}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-purple rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="fa-solid:award"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
   
            </div>
        </div>
        {/* card end */}
    </div>
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-3 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">
                            Total Loan Amount Collected
                        </p>
                        <h6 className="mb-0">{Number(loanData?.totalSum) - Number(loanData?.total_sum_be)}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-info rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="fluent:people-20-filled"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
      
            </div>
        </div>
        {/* card end */}
    </div>
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-4 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">Total Loan Number Collected</p>
                        <h6 className="mb-0">{loanData?.paidCount}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-success-main rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="solar:wallet-bold"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
             
            </div>
        </div>
        {/* card end */}
    </div>
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-5 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">Total Amount to be collected</p>
                        <h6 className="mb-0">{loanData?.total_sum_be}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-red rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="fa6-solid:file-invoice-dollar"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
     
            </div>
        </div>
        {/* card end */}
    </div>
    <div className="col">
        <div className="card shadow-none border bg-gradient-start-4 h-100">
            <div className="card-body p-20">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                    <div>
                        <p className="fw-medium text-primary-light mb-1">Total Loan Number to be collected</p>
                        <h6 className="mb-0">{loanData?.total_count_be}</h6>
                    </div>
                    <div className="w-50-px h-50-px bg-success-main rounded-circle d-flex justify-content-center align-items-center">
                        <Icon
                            icon="solar:wallet-bold"
                            className="text-white text-2xl mb-0"
                        />
                    </div>
                </div>
                <p className="fw-medium text-sm text-primary-light mt-12 mb-0 d-flex align-items-center gap-2">
                    <span className="d-inline-flex align-items-center gap-1 text-success-main">
                        <Icon icon="bxs:up-arrow" className="text-xs" /> +1,000
                    </span>
                    Last 30 days income
                </p>
            </div>
        </div>
        {/* card end */}
    </div>
</div>
  )
}

export default UnitCountOne