import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import './date.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UnitCountFour2 = () => {
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [selectedChart, setSelectedChart] = useState([]);
  const [Agency, setAgency] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true); // Add loading state

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date('2018-01-01'),
    endDate: new Date(),
    key: "selection",
  });

  const periodOptions = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: '7days', label: 'Last 7 Days' },
    { value: '1month', label: 'Last 1 Month' },
    { value: '3months', label: 'Last 3 Months' },
    { value: '6months', label: 'Last 6 Months' },
    { value: '1year', label: 'Last 1 Year' },
    { value: 'custom', label: 'Custom' },
  ];

  const handleSelects = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleApply = () => {
    setShowCalendar(false);
    fetchData();
  };

  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption);
    if (selectedOption.value === 'custom') {
      setShowCalendar(true);
    } else {
      setShowCalendar(false);
      fetchData();
    }
  };

  async function fetchData() {
    const token = localStorage.getItem('access_token');
    let periodQuery = '';

    if (selectedPeriod && selectedPeriod.value !== 'custom') {
      periodQuery = `?period=${selectedPeriod.value}`;
    } else if (selectedPeriod && selectedPeriod.value === 'custom') {
      periodQuery = `?start_date=${selectionRange.startDate.toISOString().split('T')[0]}&end_date=${selectionRange.endDate.toISOString().split('T')[0]}`;
    }

    const Respones = await fetch(`${config.apiUrl}getAllAgencies${periodQuery}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    const json_data = await Respones.json();
    setAgency(json_data.data);

    const Respones1 = await fetch(`${config.apiUrl}defaulteddashboard${periodQuery}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
    });
    const json_data1 = await Respones1.json();
    setData(json_data1);
    setLoading(false); // Set loading to false after data is fetched
  }

  useEffect(() => {
    fetchData();
  }, []);

  const agencyData = data;
  const metrics = [
    { key: 'totalLoansAssigned', label: 'Total Loans Assigned' },
    { key: 'totalLoansClosed', label: 'Total Loans Closed' },
    { key: 'totalLoanAmount', label: 'Total Loan Amount', format: (val) => `₹${val.toLocaleString()}` },
    { key: 'totalLoanAmountClosed', label: 'Total Loan Amount Closed', format: (val) => `₹${val.toLocaleString()}` },
    { key: 'performancePercentage', label: 'Performance %', format: (val) => `${val}%` },
  ];

  const chartData = metrics
    .filter(metric => metric.key === 'performancePercentage')
    .map(metric => {
      const dataPoint = { metric: metric.label };
      selectedAgencies?.forEach(agency => {
        if (agencyData && agencyData[agency]) {
          dataPoint[agency] = agencyData[agency][metric.key];
        } else {
          dataPoint[agency] = 'N/A'; // Default value if data is missing
        }
      });
      return dataPoint;
    });

  const agencies = Agency?.map((item) => item?.username || ['']);
  const chart = ["Bar Chart"];
  const chartOptions = chart.map(charts => ({
    label: charts,
    value: charts
  }));
  const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#00C49F'];

  const handleAgencyChange = (selectedOptions) => {
    setSelectedAgencies(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handlechartChange = (selectedOption) => {
    setSelectedChart(selectedOption ? [selectedOption].map(option => option.value) : []);
  };

  const agencyOptions = agencies?.map(agency => ({
    label: agency,
    value: agency
  }));

  return (
    <>
      {loading ? (
        <div>Loading...</div> // Display loader while data is being fetched
      ) : (
        <>
          <div className="col-xxl-12 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div className="col-xxl-3 col-sm-6">
                    <div style={{ position: "relative", width: "300px" }}>
                      <Select
                        options={periodOptions}
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                        placeholder="Select Period"
                      />
                      {showCalendar && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50px",
                            left: "0",
                            zIndex: 999,
                            backgroundColor: "#fff",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                          }}
                        >
                          <DateRangePicker
                            ranges={[selectionRange]}
                            onChange={handleSelects}
                            showDateDisplay={false}
                          />
                          <div style={{ marginTop: "10px", textAlign: "center" }}>
                            <button
                              onClick={handleApply}
                              style={{
                                padding: "5px 7px",
                                border: "1px solid #007BFF",
                                borderRadius: "5px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xxl-4 col-sm-6">
                    <div className="dropdown">
                      <Select
                        isMulti
                        options={agencyOptions}
                        value={agencyOptions?.filter(option => selectedAgencies?.includes(option.value))}
                        onChange={handleAgencyChange}
                        placeholder="Select Agencies"
                      />
                    </div>
                  </div>
                  <div className="col-xxl-3 col-sm-6">
                    <Select
                      options={chartOptions}
                      value={chartOptions.filter(option => selectedChart.includes(option.value))}
                      onChange={handlechartChange}
                      placeholder="Select Chart"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                {selectedAgencies?.length > 0 && (
                  <table className="table bordered-table mb-0">
                    <thead>
                      <tr>
                        <th scope="col">Metric</th>
                        {selectedAgencies.map(agencyValue => {
                          const agency = agencyOptions.find(opt => opt.value === agencyValue);
                          return <th key={agencyValue}>{agency?.label}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {metrics.map(({ key, label, format }) => (
                        <tr key={key}>
                          <td>{label}</td>
                          {selectedAgencies?.map(agencyValue => (
                            <td key={`${agencyValue}-${key}`}>
                              {agencyData && agencyData[agencyValue]
                                ? format
                                  ? format(agencyData[agencyValue][key])
                                  : agencyData[agencyValue][key]
                                : 'N/A'}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                {selectedAgencies?.length > 0 && (
                  <>
                    <table className="agency-table">
                      {/* ... keep same table structure ... */}
                    </table>
                    <div style={{ height: '500px', marginTop: '2rem' }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          data={chartData}
                          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="metric"
                            angle={-45}
                            textAnchor="end"
                            height={75}
                          />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          {selectedAgencies.map((agency, index) => (
                            <Bar
                              key={agency}
                              dataKey={agency}
                              name={agencyOptions.find(o => o.value === agency)?.label}
                              fill={colors[index % colors.length]}
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UnitCountFour2;