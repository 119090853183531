import { useState } from "react";
import config from "../../config";

const slabs = [
  "1-30", "31-60", "61-90", "91-120", "121-180", "181-365", "365+"
];

const percentages = [
  { value: "0", label: "0%" },
  { value: "25", label: "25%" },
  { value: "50", label: "50%" },
  { value: "65", label: "65%" },
  { value: "80", label: "80%" },

  { value: "100", label: "100%" },
];

export default function Offers() {
  const [selectedValues, setSelectedValues] = useState({});
  const [selectedValues1, setSelectedValues1] = useState({});


  const handleButtonClick1 = (slab, value) => {
    const currentValues = selectedValues1[slab] || [];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    setSelectedValues1({ ...selectedValues1, [slab]: newValues });
  };
  const handleButtonClick = (slab, value) => {
    const currentValues = selectedValues[slab] || [];
    const newValues = currentValues.includes(value)
      ? currentValues.filter(v => v !== value)
      : [...currentValues, value];
    setSelectedValues({ ...selectedValues, [slab]: newValues });
  };

  const handleSubmit = async () => {
    selectedValues["type"] = "daily_late";
    try {
        console.log(selectedValues);
      const response = await fetch(`${config.apiUrl}slabs `, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedValues),
      });
      
      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
      
      alert("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Submission failed. Please try again.");
    }
  };

  const handleSubmit1 = async () => {
    selectedValues["type"] = "service_fee";
    try {
        console.log(selectedValues);
      const response = await fetch(`${config.apiUrl}slabs `, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(selectedValues),
      });
      
      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
      
      alert("Data submitted successfully!");
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Submission failed. Please try again.");
    }
  };


  return (
    <>
    <div className="container mt-4">
      <div className="row g-4">
      <p className="text-center fw-bold">Select the percentage for each daily late</p>
      {slabs.map((slab) => (
          <div key={slab} className="col-md-4">
            <div className="card border-0 shadow-lg rounded-4 p-4 bg-light">
              <div className="card-body text-center">
                <h4 className="card-title fw-bold text-primary mb-3">{slab} Days</h4>
                <div className="d-flex flex-wrap justify-content-center gap-2">
                  {percentages.map((percentage) => (
                    <button
                      key={percentage.value}
                      className={`btn btn-outline-primary ${selectedValues[slab]?.includes(percentage.value) ? 'active' : ''}`}
                      onClick={() => handleButtonClick(slab, percentage.value)}
                    >
                      {percentage.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center mt-4">
        <button className="btn btn-success px-4 py-2" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
     <div className="container mt-4">
     <div className="row g-4">
     <p className="text-center fw-bold">Select the percentage for each Service Fee</p>
     {slabs.map((slab) => (
         <div key={slab} className="col-md-4">
           <div className="card border-0 shadow-lg rounded-4 p-4 bg-light">
             <div className="card-body text-center">
               <h4 className="card-title fw-bold text-primary mb-3">{slab} Days</h4>
               <div className="d-flex flex-wrap justify-content-center gap-2">
                 {percentages.map((percentage) => (
                   <button
                     key={percentage.value}
                     className={`btn btn-outline-primary ${selectedValues1[slab]?.includes(percentage.value) ? 'active' : ''}`}
                     onClick={() => handleButtonClick1(slab, percentage.value)}
                   >
                     {percentage.label}
                   </button>
                 ))}
               </div>
             </div>
           </div>
         </div>
       ))}
     </div>
     <div className="text-center mt-4">
       <button className="btn btn-success px-4 py-2" onClick={handleSubmit1}>Submit</button>
     </div>
   </div>
   </>
  );
}