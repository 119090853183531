import React, { useEffect, useState } from 'react'
import 'datatables.net-dt/js/dataTables.dataTables.js';
import Form1 from './Form1';
import Form2 from './Form2';
import Closeloan from './Closeloan';
import config from '../../config';

const LoanDetails = () => {
  const [data, setData] = useState();
  const [loan, setLoan] = useState('');
  const [activeTab, setActiveTab] = useState(1);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  async function fetchData() {
    const Respones = await fetch(`${config.apiUrl}/beta/api/collection/admindefaulter`);
    const json_data = await Respones.json();
    setData(json_data.data);
  }

  useEffect(() => {
    // fetchData();

    const storedTab = localStorage.getItem('activeTab');
    if (storedTab) {
      setActiveTab(Number(storedTab)); // Convert string to number
    }
  }, []);

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    localStorage.setItem('activeTab', tabIndex); // Save active tab to localStorage
  };

  return (
    <div className="card">
      <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div className="d-flex flex-wrap align-items-center gap-3">
          <div className="d-flex align-items-center gap-2">
            <div className="d-flex justify-content-between mb-3 gap-3">
              <button
                className={`btn ${activeTab === 1 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-18 py-100 gap-2`}
                onClick={() => handleTabChange(1)}
              >
                Pre Due
              </button>
              <button
                className={`btn ${activeTab === 2 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 ml-2`}
                onClick={() => handleTabChange(2)}
              >
                Defaulted
              </button>
              <button
                className={`btn ${activeTab === 3 ? 'btn-primary' : 'btn-outline-primary'} rounded-3 px-4 py-2 gap-2`}
                onClick={() => handleTabChange(3)}
              >
                Closed Via link
              </button>
            </div>
          </div>
        </div>
      </div>

      {activeTab === 1 && <Form1 />}
      {activeTab === 2 && <Form2 />}
      {activeTab === 3 && <Closeloan />}
    </div>
  )
}

export default LoanDetails