import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import config from '../../config';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import * as XLSX from 'xlsx';

const periodOptions = [
  { value: 'alumni', label: 'alumni' },
  { value: 'stucred', label: 'stucred' },
  { value: 'Both', label: 'Both' },
];

const UnitCountFour = () => {
  const [data, setData] = useState();
  const [loadingbutton, setloadingbutton] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  const handlePeriodChange = (selectedOption) => {
    console.log(selectedOption);
    setSelectedPeriod(selectedOption);
  };

  const didFetchData = useRef(false); // Ref to ensure fetchData is called once
  const token = localStorage.getItem('access_token');
  const individual = useSelector((state) => state.Individual.individual_role);

  async function fetchData() {
    try {
      setLoading(true); // Set loading state before fetching data
      const periodQuery = selectedPeriod ? `?user_type=${selectedPeriod.value}` : '';
  
      const urls = [
        `${config.apiUrl}all_loans${periodQuery}`,
        `${config.apiUrl}pre_dashboard${periodQuery}`,
        `${config.apiUrl}defaultedloan${periodQuery}`,
        `${config.apiUrl}close_loandash${periodQuery}`,
        `${config.apiUrl}defclose_loandash${periodQuery}`,
      ];
  
      const headers = {
        'Content-Type': 'application/json',
        Authorization: token,
      };
  
      // Fetch all data in parallel
      const responses = await Promise.all(urls.map(url => fetch(url, { method: 'GET', headers })));
  
      // Convert all responses to JSON in parallel
      const jsonData = await Promise.all(responses.map(res => res.json()));
  
      // Update state variables with fetched data
      setData(jsonData[0]?.data?.[0] || {});
      setData1(jsonData[1]?.data?.[0] || {});
      setData2(jsonData[2]?.data?.[0] || {});
      setData3(jsonData[3]?.[0] || {});
      setData4(jsonData[4]?.[0] || {});
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Ensure loading state is updated
    }
  }
  

  const spinnerStyle = {
    border: '4px solid rgba(255, 255, 255, 0.3)',
    borderTop: '4px solid #3498db',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    animation: 'spin 1s linear infinite',
    marginRight: '8px', // Space between spinner and text
  };

  // Inline styles for the button when loading
  const loadingTextStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  // Keyframes for the spinning animation (defined in JS)
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(
    `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `,
    styleSheet.cssRules.length
  );

  const handleExport = (data, filename) => {
    const worksheet = XLSX.utils.json_to_sheet([data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  useEffect(() => {
    fetchData();
  }, [selectedPeriod]);

  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div style={spinnerStyle}></div>
        </div>
      ) : (
        <>
          {/* Dashboard Widget Start */}
          <div className="col-xxl-8 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <p>Life time disbursed data</p>
                  {individual?.loan_details?.export_access === true && (
                    <button
                      className="btn btn-sm btn-primary-600"
                      onClick={() => handleExport(data, 'Life_time_disbursed_data')}
                    >
                      {loadingbutton ? (
                        <span style={loadingTextStyle}>
                          <div style={spinnerStyle}></div>
                          downloading...
                        </span>
                      ) : (
                        <>
                          <i className="ri-add-line" />
                          Download Excel
                        </>
                      )}
                    </button>
                  )}
                </div>

                {/* Header for Total Loan Count */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Loan Count</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">{(data?.total_count || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>

                {/* Header for Total Outstanding Amount */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Outstanding Amount</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">₹{(data?.total_loan_amt || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dashboard Widget End */}
          {/* Dashboard Widget Start */}
          <div className="col-xxl-4 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <Select
                    options={periodOptions}
                    value={selectedPeriod}
                    onChange={handlePeriodChange}
                    placeholder="Select User Type"
                    style={{ width: '600px' }} // Adjust the width as needed
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <p>pre due disbursed data</p>
                  {individual?.loan_details?.export_access === true && (
                    <button
                      className="btn btn-sm btn-primary-600"
                      onClick={() => handleExport(data1, 'Pre_due_disbursed_data')}
                    >
                      {loadingbutton ? (
                        <span style={loadingTextStyle}>
                          <div style={spinnerStyle}></div>
                          downloading...
                        </span>
                      ) : (
                        <>
                          <i className="ri-add-line" />
                          Download Excel
                        </>
                      )}
                    </button>
                  )}
                </div>

                {/* Header for Total Loan Count */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Loan Count</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">{(data1?.total_count || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>

                {/* Header for Total Outstanding Amount */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Outstanding Amount</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">₹{(data1?.total_loan_amt || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <p>DPD disbursed data</p>
                  {individual?.loan_details?.export_access === true && (
                    <button
                      className="btn btn-sm btn-primary-600"
                      onClick={() => handleExport(data2, 'DPD_disbursed_data')}
                    >
                      {loadingbutton ? (
                        <span style={loadingTextStyle}>
                          <div style={spinnerStyle}></div>
                          downloading...
                        </span>
                      ) : (
                        <>
                          <i className="ri-add-line" />
                          Download Excel
                        </>
                      )}
                    </button>
                  )}
                </div>

                {/* Header for Total Loan Count */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Loan Count</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">{(data2?.total_count || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>

                {/* Header for Total Outstanding Amount */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Outstanding Amount</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">₹{(data2?.total_loan_amt || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <p>Defaulted Close data</p>
                  {individual?.loan_details?.export_access === true && (
                    <button
                      className="btn btn-sm btn-primary-600"
                      onClick={() => handleExport(data4, 'Defaulted_Close_data')}
                    >
                      {loadingbutton ? (
                        <span style={loadingTextStyle}>
                          <div style={spinnerStyle}></div>
                          downloading...
                        </span>
                      ) : (
                        <>
                          <i className="ri-add-line" />
                          Download Excel
                        </>
                      )}
                    </button>
                  )}
                </div>

                {/* Header for Total Loan Count */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Loan Count</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">{(data4?.total_count || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>

                {/* Header for Total Outstanding Amount */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Outstanding Amount</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">₹{(data4?.total_loan_amt || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-sm-6">
            <div className="card px-24 py-16 shadow-none radius-8 border h-100 bg-gradient-start-3">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <p>On Time Closed Loans</p>
                  {individual?.loan_details?.export_access === true && (
                    <button
                      className="btn btn-sm btn-primary-600"
                      onClick={() => handleExport(data3, 'On_Time_Closed_Loans')}
                    >
                      {loadingbutton ? (
                        <span style={loadingTextStyle}>
                          <div style={spinnerStyle}></div>
                          downloading...
                        </span>
                      ) : (
                        <>
                          <i className="ri-add-line" />
                          Download Excel
                        </>
                      )}
                    </button>
                  )}
                </div>

                {/* Header for Total Loan Count */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Loan Count</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">{(data3?.total_count || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>

                {/* Header for Total Outstanding Amount */}
                <div className="d-flex justify-content-between align-items-center mb-8">
                  <div>
                    <span className="fw-medium text-secondary-light text-md">Total Outstanding Amount</span>
                  </div>
                  <div>
                    <div>
                      <h6 className="fw-semibold">₹{(data3?.total_loan_amt || 0).toLocaleString('en-IN')}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UnitCountFour;