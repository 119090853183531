import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import config from '../../config';
import * as XLSX from 'xlsx';  // Library for handling Excel file parsing
import AWS from 'aws-sdk';
import FailedMessage from './FailedMessage';


const AgentLoan = () => {
    const [data, setData] = useState()
    const[loan, setLoan] = useState('')
    const [activeTab, setActiveTab] = useState(1);
    const [agency, setAgency] = useState()
    const [file, setFile] = useState(null); // Store the selected file in state
    const [totalData, setTotal] = useState()
    const [selection, setSelection] = useState()
    const [total_count, setTotalCount] = useState()
        const [totalPages , setTotalPages] = useState()
        const [currentPage, setCurrentPage] = useState(1)
    const [ID , setID] = useState()
    const user_id = useSelector((state)=> state?.user?.currentUser?._id)
    const individual = useSelector((state)=>  state?.Individual?.individual_role)
              const [loadingbutton, setloadingbutton] = useState()
              const [error, setError] = useState(null);
               const [loading, setLoading] = useState(true); // Loading state
    const [mappingLoading, setMappingLoading] = useState(false); // Loading state for mapping
    const [unmappingloading,setUnmappingloading] = useState('')
    const [excelMessage , setExcelMessage] = useState()
    const [uploadExcel, setuploadExcel] = useState()

    const [message, setMessage] = useState('');


    const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const handleOpenDialog = () => {
        setIsDialogOpen(true);
      };
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
      };
                   const fetchDataForPage = async (page) => {
                       const token = localStorage.getItem('access_token')
                 
                       try {
                         const response = await fetch(`${config.apiUrl}getagent/${user_id}?pages=${page}&excel=true`, {
                           headers: {
                             'Authorization': token,
                             'Content-Type': 'application/json',
                           },
                         });
                   
                         if (!response.ok) {
                           throw new Error('Failed to fetch data');
                         }
                   
                         const data = await response.json();
                  
                         return data;  // Assuming data has the structure { data: [...], totalPages: ... }conso
                       } catch (error) {
                         console.error(error);
                         setError(error.message);
                         return null;
                       }
                     };
                        const spinnerStyle = {
                        border: '4px solid rgba(255, 255, 255, 0.3)',
                        borderTop: '4px solid #3498db',
                        borderRadius: '50%',
                        width: '24px',
                        height: '24px',
                        animation: 'spin 1s linear infinite',
                        marginRight: '8px', // Space between spinner and text
                      };
                    
                      // Inline styles for the button when loading
                      const loadingTextStyle = {
                        display: 'flex',
                        alignItems: 'center',
                      };
                    
                      // Keyframes for the spinning animation (defined in JS)
                      const styleSheet = document.styleSheets[0];
                      styleSheet.insertRule(`
                        @keyframes spin {
                          0% { transform: rotate(0deg); }
                          100% { transform: rotate(360deg); }
                        }
                      `, styleSheet.cssRules.length);
                  
                      // Function to handle exporting all data
                      const handleExport = async () => {
                        setloadingbutton(true);
                        let allData = [];
                        let totalPages = 0;
                    
                        try {
                          // First, get the data from the first page
                          const firstPageData = await fetchDataForPage(1);
                          if (firstPageData) {
                            totalPages = firstPageData.totalPage || 1;
                            allData = firstPageData.data;
                          }
                    
                          // If there are more pages, fetch the remaining pages
                          for (let page = 2; page <= totalPages; page++) {
                            const pageData = await fetchDataForPage(page);
                            if (pageData) {
                              allData = [...allData, ...pageData.data];
                            }
                          }
                          console.log(allData,"===================")
                    
                          // Now generate the Excel file using `xlsx` library
                          const ws = XLSX.utils.json_to_sheet(allData);
                          const wb = XLSX.utils.book_new();
                          XLSX.utils.book_append_sheet(wb, ws, 'Data');
                    
                          // Export the file
                          XLSX.writeFile(wb, 'data.xlsx');
                          setloadingbutton(false);

                        } catch (error) {
                          setError('Error exporting data');
                          setloadingbutton(false);

                        } finally {
                          setLoading(false);
                        }
                      };
      const convertExcelToJson = (file) => {
             return new Promise((resolve, reject) => {
               const reader = new FileReader();
               reader.onload = (e) => {
                 const data = new Uint8Array(e.target.result);
                 const workbook = XLSX.read(data, { type: 'array' });
           
                 // Assuming the first sheet is what you need
                 const sheetName = workbook.SheetNames[0];
                 const sheet = workbook.Sheets[sheetName];
           
                 // Convert sheet to JSON
                 const jsonData = XLSX.utils.sheet_to_json(sheet);
                 resolve(jsonData);
               };
               reader.onerror = (error) => reject(error);
           
               reader.readAsArrayBuffer(file);
             });
           };
           const unmapping = async(e)=>{
            setUnmappingloading(true)

               let type ;
               e.preventDefault()
               console.log('unmapping', e.target.innerText)
               if(e.target.innerText === 'Replace'){   
                   type = 'Replace'
           
               }else{
                   type = 'Unmap'
           
           
               }
               const token = localStorage.getItem('access_token')
            const response =  await fetch(`${config.apiUrl}unmapping_loans_agent?loan_no=${totalData}&type=${type}&id=${ID}`,{
                   method: 'get',
                   headers:{
                       'Authorization': token,  // Replace with your actual token
                       'Content-Type': 'application/json'  // Include this if you're sending JSON data
                     }
               })
               if(response.ok){
                    setMessage('Data Unmapped Successfully')
                    setUnmappingloading(false)
                    window.location.reload();

               }
           }
           
 
           
            
            
            const sendUrlToBackend = async (fileUrl) => {
              const token = localStorage.getItem('access_token');
              const response = await fetch(`${config.apiUrl}preUpload`, {
                method: 'POST',
                headers: {
                  'Authorization': token,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  fileUrl,  // Send the S3 file URL to the backend
                }),
              });
            
              if (!response.ok) {
                console.error('Failed to send file URL to backend');
              } else {
                const result = await response.json();
              }
            };
            
      const handleFileChange = async (event) => {
        event.preventDefault()
        setuploadExcel(true)
        const token = localStorage.getItem('access_token')
        const selectedFile = event.target.files[0];
        
        // Set the file to state
        setFile(selectedFile);
        let jsonData = await convertExcelToJson(selectedFile)
        console.log(jsonData,"==============================2")
        jsonData = jsonData.map(item => ({
          loan_id: item?.loanId,
          MappedAgent: item?.MappedAgent
      })); 
      console.log(jsonData,"===================1=========")
      // Create a new FormData object
            const splitIntoChunks = (data, chunkSize) => {
                                  const chunks = [];
                                  for (let i = 0; i < data.length; i += chunkSize) {
                                      chunks.push(data.slice(i, i + chunkSize));
                                  }
                                  return chunks;
                              };
                          
                              const postChunks = async (jsonData, chunkSize = 1000) => {
                                  // Split jsonData into smaller chunks
                                  const chunks = splitIntoChunks(jsonData, chunkSize);
                                  const totalChunks = chunks.length;
                                  let currentChunkIndex = 0;
                                  
                                  while (currentChunkIndex < totalChunks) {
                                      const chunk = chunks[currentChunkIndex];
                                      try {
                                        const response = await fetch(`${config.apiUrl}upload_loan_agency`, {
                                          method: "POST",
                                              headers: {
                                                  'Authorization': token, // Your AWS credentials as Authorization header
                                                  'Content-Type': 'application/json', // Assuming your request expects JSON content
                                              },
                                              body: JSON.stringify({ data: chunk }) // Send the chunk of data in the body
                                          });
                          
                                          if (response.ok) {
                                              console.log(`Data chunk ${currentChunkIndex + 1} of ${totalChunks} successfully sent.`);
                                              currentChunkIndex++;  // Move to the next chunk if current one is successful
                                          } else {
                                            setuploadExcel(false)
                                            setExcelMessage('Unable to upload data');                                              break; // Break the loop if chunk fails (or you can add retry logic)
                                          }
                                      } catch (error) {
                                        setuploadExcel(false)
                                        setExcelMessage('Unable to upload data');                                          break; // Stop on error, can also add retry logic here
                                      }
                                  }
                          
                                  if (currentChunkIndex === totalChunks) {
                                      console.log("All data chunks successfully sent.");
                                      setuploadExcel(false)
                                      setExcelMessage('Successfully Agent Uploaded')
                                      window.location.reload();

                                  } else {
                                    setuploadExcel(false)
                                    setExcelMessage('Unable to upload data');                                  }
                              };
                          
                              // Usage
                              await postChunks(jsonData, 1000); // Send data in chunks of 3000 items
      
      };
      const PAGE_LIMIT = 5; // Number of pages to show before and after current page

      const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
      };
      
      const getPageNumbers = () => {
          const pages = [];
          const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
          const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
      
          if (leftLimit > 1) {
              pages.push(1);
              if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
          }
      
          for (let i = leftLimit; i <= rightLimit; i++) {
              pages.push(i);
          }
      
          if (rightLimit < totalPages) {
              if (rightLimit < totalPages - 1) pages.push('...'); 
              pages.push(totalPages);
          }
      
          return pages;
      };
    async function fetchData(){
        const token = localStorage.getItem('access_token')
        const Respones = await fetch(`${config.apiUrl}getagent/${user_id}?pages=${currentPage}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },

        })
        const agency = await fetch(`${config.apiUrl}agent/${user_id}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })

        const json_datas = await agency.json()
        setAgency(json_datas.data)
        const json_data = await Respones.json()
        setData(json_data.data)
        setTotalPages(json_data.totalPage)

        
    }
    const handleSelection = async(e)=>{
        setTotal(e.target.value)
    }
    const handleAgency = async(e)=>{
        setID(e.target.value)

    }
    const handlemapped = async(e)=>{
      setMappingLoading(true)
      e.preventDefault()
        const token = localStorage.getItem('access_token')
        let response = await fetch(`${config.apiUrl}UpdateAgent/${user_id}?loan_no=${totalData}&username=${ID}`,{
            method: 'PUT',
                  headers: {
                    'Authorization': token,  // Replace with your actual token
                    'Content-Type': 'application/json'  // Include this if you're sending JSON data
                  },
        })
        if(response.ok){
          setMessage('Data Mapped Successfully')
          setMappingLoading(false)
          window.location.reload();

      }

    }

    const handleFile = async (e)=>{
        const token = localStorage.getItem('access_token')
        let Respones = await fetch(`${config.apiUrl}getagent/${user_id}?excel=true`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const json_data = await Respones.json('')

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'loan_mapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }
   
    const handletotal = async(e)=>{
      const token = localStorage.getItem('access_token')
      // const total_data = await fetch(`${config.apiUrl}slab_totalLoans?days=${selection}`,{
      //     method: 'GET',
      //     headers: {
      //       'Authorization': token,  // Replace with your actual token
      //       'Content-Type': 'application/json'  // Include this if you're sending JSON data
      //     },
      // })
      // const total_json_data = await total_data.json()
      setTotalCount(totalData)
  
  
    }
    useEffect(()=>{
        fetchData()
    },[selection,file])


  return (
    <> 
    <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">

<div className="d-flex flex-wrap align-items-center gap-3">
    <div className="icon-field">
        <input
            type="number"
            name="#0"
            className="form-control form-control-sm w-auto"
            placeholder="Enter No. of loans" 
            onChange={handleSelection}
        />
       
    </div>
 <select id='status' className="form-select form-select-sm w-auto" defaultValue="Agency" onChange={handleAgency}>
 <option value="Agency" disabled>
     Select Agency
 </option>

 {agency?.map((data,index)=>(
             <option key={index} value={data._id}>{data.username}</option>

 ))}
 </select>
 <button
  className="btn-primary rounded-2 px-6 py-8 ml-2"
  style={{ backgroundColor: '#007bff', color: 'white' }}
  data-bs-toggle="modal"
  data-bs-target="#Modal1"
  onClick={handletotal}
  disabled={!ID} // Disable if no agency is selected
>
  Auto Map
</button>


     <button
className="btn-primary rounded-2 px-6 py-8 ml-2"
style={{ backgroundColor: '#007bff', color: 'white' }}
                       data-bs-toggle="modal"
                     data-bs-target="#Modal2"
                     onClick={handletotal}
>
       UN-MAP
     </button>




</div>
<div className="d-flex flex-wrap align-items-center gap-3">

{individual?.loan_details?.export_access === true && (
      <button className="btn btn-sm btn-primary-600" onClick={handleExport}>
              {loadingbutton ? (
              <span style={loadingTextStyle}>
              <div style={spinnerStyle}></div>
              downloading...
            </span>

        ):(   
          <>     
          <i className="ri-add-line" />  
          Download Excel
          </> 
        )}
      </button>
    )}

                            {uploadExcel ? (
                  <span style={loadingTextStyle}>
                  <div style={spinnerStyle}></div>
                  uploading...
                </span>
    
            ):(   
              <>    
                  <label
                            htmlFor="file-upload-name"
                            className="mb-14 border border-neutral-600 fw-medium text-secondary-light px-16 py-10 radius-12 d-inline-flex align-items-center gap-1 bg-hover-neutral-200"
                        > 
                             {excelMessage && <FailedMessage message={excelMessage} onHide={handleHideMessage} />}

                           <Icon icon="solar:upload-linear" className="text-xl"></Icon>
                     upload xlsx map
                     <input
                         type="file"
                         className="form-control w-auto mt-24 form-control-lg"
                         id="file-upload-name"
                         multiple
                         hidden
                         onChange={handleFileChange}
                     />
                        </label>
              </> 
            )}
</div>
</div>

<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
 <thead>
     <tr>
         <th scope="col">
             <div className="form-check style-check d-flex align-items-center">
                 <input
                     className="form-check-input"
                     type="checkbox"
                     defaultValue=""
                     id="checkAll"
                 />
                 <label className="form-check-label" htmlFor="checkAll">
                     Agency Name
                 </label>
             </div>
         </th>
         <th scope="col">Loan Id</th>
         <th scope="col">Loan Amount</th>
 
         <th scope="col">Due Date</th>

         <th scope="col">Name</th>
         <th scope="col">Mobile Number</th>
         <th scope="col">Email</th>


         {/* <th scope="col">Action</th> */}
     </tr>
 </thead>
 <tbody>
 {data?.map((data,index)=>(

<tr key={index}>
<td>
<div className="form-check style-check d-flex align-items-center">
<input
 className="form-check-input"
 type="checkbox"
 defaultValue=""
 id="check1"
/>
<label className="form-check-label" htmlFor="check1">
 {data?.agencies_name}
</label>
</div>
</td>
<td>
<Link to="#" className="text-primary-600">
{data?.loan_no}
</Link>
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
 {data?.loan_amount}
</h6>
</div>
</td>

<td>{(new Date(data?.due_date).toISOString().split('T')[0])}</td>
<td>{data?.defaulter_name}</td>
<td>{data?.defaulter_mobile}</td>


<td>
{" "}
<span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
{data?.defaulter_email}
</span>
</td>

{/* <td>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="iconamoon:eye-light" />
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="lucide:edit" />
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-danger-focus text-danger-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="mingcute:delete-2-line" />
</Link>
</td> */}
</tr>
 ))}

   
 </tbody>
</table>

</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>

</div>
<div
className="modal fade"
id="Modal1"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
 <div className="modal-content radius-16 bg-base">
     <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
         <h1 className="modal-title fs-5" id="exampleModalLabel">
             Auto Mapped
                        </h1>
         <button
             type="button"
             className="btn-close"
             data-bs-dismiss="modal"
             aria-label="Close"
         />
     </div>
     <div className="modal-body p-24">
         <form action="#">
             <div className="row">
             <div className="d-flex align-items-center justify-content-between border border-red-300 bg-red-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Are you sure mapping the data:</p>
<p className="text-right text-gray-600">{totalData}</p>
</div>
{message && <FailedMessage message={message} onHide={handleHideMessage} />}


                 <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                     <button
                         type="reset"
                         className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                           data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                         Cancel
                     </button>
                     <button
                         type="submit"
                         className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                         onClick={handlemapped}
                         disabled={mappingLoading} // Disable button while loading

                     >
              {mappingLoading ? (
                                                <span style={loadingTextStyle}>
                                                    <div style={spinnerStyle}></div>
                                                    Mapping...
                                                </span>
                                            ) : (
                                                'Mapping'
                                            )}                     </button>
                 </div>
             </div>
         </form>
     </div>
 </div>
</div>
</div>
<div
  className="modal fade"
  id="Modal2"
  tabIndex={-1}
  aria-labelledby="exampleModalLabel1"
  aria-hidden="true"
>
  <div className="modal-dialog modal-lg modal-dialog modal-dialog-centered w-50">
    <div className="modal-content radius-16 bg-base w-100">
      <div className="modal-header py-9 px-10 border border-top-0 border-start-0 border-end-0 w-100">
        <h1 className="modal-title fs-5" id="exampleModalLabel1">
          UN-MAP
        </h1>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />
      </div>
      <div className="modal-body p-24">
        <form action="#">
          <div className="row">
            {/* Warning Message */}
            <div className="d-flex align-items-center justify-content-between border border-red-300 bg-red-50 p-2 rounded shadow-sm ">
              <p className="fw-semibold text-gray-500">Are you sure un-mapping the data:</p>
              <p className="text-right text-gray-600">{total_count}</p>
            </div>

            {/* Select Range */}
            <div className="mt-3">
              <label className="fw-semibold text-gray-700">Enter No. of loan:</label>
              <div className="icon-field">
                <input
                  type="number"
                  name="#0"
                  className="form-control form-control-sm w-50"
                  placeholder="Enter No. of loans"
                  onChange={handleSelection}
                />
              </div>
            </div>

            {/* Select Agency */}
            <div className="mt-3 w-50">
              <label className="fw-semibold text-gray-700 w-50">Select Agency:</label>
              <select id='status' className="form-select form-select-sm w-auto" defaultValue="Agency" onChange={handleAgency}>
 <option value="Agency" disabled>
     Select Agency
 </option>

 {agency?.map((data,index)=>(
             <option key={index} value={data._id}>{data.username}</option>

 ))}
 </select>
            </div>

            {/* Replace Data with Another Agency */}

            {/* Buttons */}
            <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
              <button
                type="reset"
                className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
              <button
                type="button"
                className="border border-warning-600 bg-hover-warning-200 text-warning-600 text-md px-40 py-11 radius-8"
                onClick={unmapping}
                disabled={unmappingloading} // Disable button while loading

              >
                      {unmappingloading ? (
                                                <span style={loadingTextStyle}>
                                                    <div style={spinnerStyle}></div>
                                                    UN-MAP...
                                                </span>
                                            ) : (
                                                'UN-MAP'
                                            )}
              </button>
              <button
                type="button"
                className="border border-warning-600 bg-hover-warning-200 text-warning-600 text-md px-40 py-11 radius-8"
                onClick={unmapping}
                disabled={unmappingloading} // Disable button while loading

              >
                      {unmappingloading ? (
                                                <span style={loadingTextStyle}>
                                                    <div style={spinnerStyle}></div>
                                                    Replace...
                                                </span>
                                            ) : (
                                                'Replace'
                                            )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


 </>  
      )
}

export default AgentLoan