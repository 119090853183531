import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const containerStyle = {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f5f5f5',
  };

  const contentStyle = {
    textAlign: 'center',
  };

  const titleStyle = {
    fontSize: '120px',
    fontWeight: 'bold',
    color: '#f56c42',
    animation: 'fadeIn 1.5s ease-in-out',
  };

  const messageStyle = {
    fontSize: '18px',
    color: '#555',
    margin: '20px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#f56c42',
    color: '#fff',
    textDecoration: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  };

  const buttonHoverStyle = {
    backgroundColor: '#e45c35',
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={titleStyle}>404</h1>
        <p style={messageStyle}>Oops! The page you're looking for doesn't exist.</p>
        <Link 
          to="/" 
          style={buttonStyle} 
          onMouseOver={(e) => e.target.style.backgroundColor = buttonHoverStyle.backgroundColor} 
          onMouseOut={(e) => e.target.style.backgroundColor = buttonStyle.backgroundColor}
        >
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
