import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import config from '../config';
import { useLocation } from 'react-router-dom';


const AgentListLayer = () => {
    const [userData , setuseData] = useState()
    const individual = useSelector((state)=>  state.Individual.individual_role)
        const [currentPage, setCurrentPage] = useState(1)
            const [totalPages , setTotalPages] = useState()
        const location = useLocation();
        const query = location.search.substring(1);  
    const user_id = useSelector((state)=> state?.user?.currentUser?._id)
    async function fetchData(){
        const token = localStorage.getItem('access_token')
        const data = await fetch(`${config.apiUrl}agent/${user_id}?pages=${currentPage}`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const jsonData = await data.json()
        setuseData(jsonData.data)
        setTotalPages(jsonData.totalPages)

    }
    const handlePageClick = (page) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page)
        fetchData();
    };
    const PAGE_LIMIT = 10
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
    const handleFile = async (e)=>{
        const token = localStorage.getItem('access_token')
        let Respones = await fetch(`${config.apiUrl}mapped_collection?excel=true`,{
            method: 'GET',
            headers: {
              'Authorization': token,  // Replace with your actual token
              'Content-Type': 'application/json'  // Include this if you're sending JSON data
            },
        })
        const json_data = await Respones.json('')

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'loan_mapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }
    useEffect(()=>{
        fetchData()

    },[])
  return (
    <div className="card h-100 p-0 radius-12">
    <div className="card-header border-bottom bg-base py-16 px-24 d-flex align-items-center flex-wrap gap-3 justify-content-between">
        <div className="d-flex align-items-center flex-wrap gap-3">
            <span className="text-md fw-medium text-secondary-light mb-0">Show</span>
            <select id="number"className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Number">
                <option value="Select Number" disabled>
                    Select Number
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
            </select>
            <form className="navbar-search">
                <input
                    type="text"
                    className="bg-base h-40-px w-auto"
                    name="search"
                    placeholder="Search"
                />
                <Icon icon="ion:search-outline" className="icon" />
            </form>
            <select id="action" className="form-select form-select-sm w-auto ps-12 py-6 radius-12 h-40-px" defaultValue="Select Status">
                <option value="Select Status" disabled>
                    Select Status
                </option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
            </select>
        </div>
        <div className="d-flex flex-wrap align-items-center gap-3">

{individual?.agent_list?.export_access === true && (

<button className="btn btn-sm btn-primary-600" onClick={handleFile}>
        <i className="ri-add-line" /> Download XLSX
    </button>
)}
    <Link
            to="/add-agent"
            className="btn btn-primary text-sm btn-sm px-12 py-12 radius-8 d-flex align-items-center gap-2"
        >
            <Icon
                icon="ic:baseline-plus"
                className="icon text-xl line-height-1"
            />
            Add New User
        </Link>
</div>
    
    </div>
    <div className="card-body p-24">
        <div className="table-responsive scroll-sm">
            <table className="table bordered-table sm-table mb-0">
                <thead>
                    <tr>
                 
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-center">
                            Login status
                        </th>
                        <th scope="col">Created</th>

                        <th scope="col">Action</th>

                 
                    </tr>
                </thead>
                <tbody>
{userData?.map((data, index)=>(
         <tr key={index}>
                   
         <td>
             <div className="d-flex align-items-center">
                 <img
                     src={data.profilePicture}
                     alt="Wowdash"
                     className="w-40-px h-40-px rounded-circle flex-shrink-0 me-12 overflow-hidden"
                 />
                 <div className="flex-grow-1">
                     <span className="text-md mb-0 fw-normal text-secondary-light">
                         {data.username}
                     </span>
                 </div>
             </div>
         </td>
         <td>
             <span className="text-md mb-0 fw-normal text-secondary-light">
                {data.email}
             </span>
         </td>
         <td>{data.state}</td>
         <td>{data.city}</td>
         <td>{data.role}</td>

         <td className="text-center">
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data?.status
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.status ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <span
                                                className={`px-24 py-4 radius-4 fw-medium text-sm ${data?.isloggedIn
                                                    ? "bg-success-focus text-success-600 border border-success-main"
                                                    : "bg-danger-focus text-danger-600 border border-danger-main"
                                                    }`}
                                            >
                                                {data.isloggedIn ? "Active" : "Inactive"}
                                            </span>
                                        </td>
                                        <td>{new Date(data.createdAt).toLocaleString()}</td>

          <td className="text-center">
                <div className="d-flex align-items-center gap-10 justify-content-center">
                  
                    <NavLink
                    to ={`/agent-profile?${data?._id}`}
                        className="bg-success-focus text-success-600 bg-hover-success-200 fw-medium w-40-px h-40-px d-flex justify-content-center align-items-center rounded-circle"
                    >
                        <Icon icon="lucide:edit" className="menu-icon" />
                    </NavLink>
               
                </div>
            </td>
     </tr>
))}
           
              
                </tbody>
            </table>
        </div>
 <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
             <span>
                 Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
             </span>
             <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                 {/* Previous Button */}
                 <li className="page-item">
                     <Link
                         className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                         to="#"
                         onClick={() => handlePageClick(currentPage - 1)}
                         disabled={currentPage === 1}
                     >
                         <Icon icon="ep:d-arrow-left" className="text-xl" />
                     </Link>
                 </li>
 
                 {/* Page Numbers */}
                 {getPageNumbers().map((page, index) => (
                     <li key={index} className="page-item">
                         {/* Render page number or ellipsis */}
                         <Link
                             className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                 page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                             }`}
                             to="#"
                             onClick={() => page !== '...' && handlePageClick(page)}
                         >
                             {page}
                         </Link>
                     </li>
                 ))}
 
                 {/* Next Button */}
                 <li className="page-item">
                     <Link
                         className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                         to="#"
                         onClick={() => handlePageClick(currentPage + 1)}
                         disabled={currentPage === totalPages}
                     >
                         <Icon icon="ep:d-arrow-right" className="text-xl" />
                     </Link>
                 </li>
             </ul>
         </div>
    </div>
</div>  )
}

export default AgentListLayer