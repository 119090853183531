import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../config';
import FailedMessage from './child/FailedMessage';
import { useLocation } from 'react-router-dom';

const ViewProfileLayer = () => {
  const [imagePreview, setImagePreview] = useState('assets/images/user-grid/user-grid-img13.png');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [user_data, setUser_data] = useState()
  const [role, setRole] = useState()
      const user_id = useSelector((state) => state?.user?.currentUser?._id)
  
  const [passData, setPassData] = useState({
    password: '',
    confirmPassword: ''
  })
  const [inputData, setInputData] = useState({
    username: '',
    email: '',
    mobileNumber: '',
    state: '',
    city: '',
    role: '',
    status: '',
    proprity_days: [
        { slab1: '' },  // proprity_days[0] with slab1 property
        { slab2: '' }   // proprity_days[1] with slab2 property
      ],
      ServiecePercentage: [
        {slab1: 0},
        {slab2: ''}

      ],
      DailyPercentage:[
        {slab1: 0},
        {slab2: ''}
      ]
  });
  const location = useLocation();
  const query = location.search.substring(1);

  const current_user = useSelector((state)=> state.user)
  const [loading, setLoading] = useState(false); // Loading state
  const [message, setMessage] = useState()


  async function fetchData(){
    const token = localStorage.getItem('access_token')
    const res = await fetch(`${config.apiUrl}user_data/${query}`,{
        method: 'GET',  // or 'POST' depending on the request type
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        }
    })
    const access_data = await fetch(`${config.apiUrl}Access_data`,{
        method: 'GET',  // or 'POST' depending on the request type
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        }
    })
    let json_data = await access_data.json()
    setRole(json_data.user)

    let data = await res.json()
    data = data.user_data
    setImagePreview(data?.profilePicture)
    setUser_data(data)
    setInputData(data);

  }
  const handlepassword = (e) =>{
    const { id, value } = e.target;

    setPassData((prevData) => {
      const updatedData = { ...prevData };
  
      switch (id) {
        case 'password':
        case 'confirmPassword':
            updatedData[id] = value;
            break

     
      }
      console.log(updatedData,"=================>>>")
  
      return updatedData;
    });
  };
  
  const handlepassSubmit = async(e)=>{
    const token = localStorage.getItem('access_token')
    setLoading(true)
    
    const dataFetch = await fetch(`${config.apiUrl}changePassword/${query}`,{
        method: 'PATCH',
        headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
                  body: JSON.stringify(passData),
                })
                const dataMessage = await dataFetch.json()
                console.log(dataMessage,"=================jjjj")
                if(dataFetch.ok){
                    setLoading(false)
                    window.location.reload()

                }
                if(dataFetch.ok){
                    setLoading(false)
                    window.location.reload()

                }
                if(dataMessage){
                    setMessage(dataMessage.message)
                    window.location.reload()

                }


  }

  const handlechange = (e) => {
    const { id, value } = e.target;

    setInputData((prevData) => {
      const updatedData = { ...prevData };
  
      switch (id) {
        case 'username':
        case 'email':
        case 'mobileNumber':
        case 'state':
        case 'city':
        case 'role':
          updatedData[id] = value;
          break;
        case 'slab1':
          updatedData.proprity_days[0] = { ...updatedData.proprity_days[0], slab1: value };
          break;
        case 'slab2':
          updatedData.proprity_days[1] = { ...updatedData.proprity_days[1], slab2: value };
          break;
        case 'service':
          updatedData.ServiecePercentage[0] = { ...updatedData.ServiecePercentage[0], slab2: value };
          break;
        case 'DailyLate':
          updatedData.DailyPercentage[0] = { ...updatedData.DailyPercentage[0], slab2: value };
          break;
        default:
          console.warn(`Unhandled input id: ${id}`);
      }
  
      return updatedData;
    });
  };
  useEffect(()=>{
    fetchData()

  },[])
  // Toggle function for password field
  const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
  };

  // Toggle function for confirm password field
  const toggleConfirmPasswordVisibility = () => {
      setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const readURL = (input) => {
      if (input.target.files && input.target.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
              setImagePreview(e.target.result);
          };
          reader.readAsDataURL(input.target.files[0]);
      }
  };
  console.log(inputData,passData,"=====================================================jjsjsjjsjj")


  const handleSubmit = async(e)=>{
    const token = localStorage.getItem('access_token')
    setLoading(true)
    inputData.profilePicture = imagePreview.toString('base64')
    
    const dataFetch = await fetch(`${config.apiUrl}user_update/${query}`,{
        method: 'PATCH',
        headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
                  body: JSON.stringify(inputData),
                })
                const dataMessage = await dataFetch.json()
                console.log(dataMessage,"=================jjjj")
                if(dataFetch.ok){
                    setLoading(false)
                }
                if(dataFetch.ok){
                    setLoading(false)
                }
                if(dataMessage){
                    setMessage(dataMessage.message)

                }


  }
  const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides

  
  return (
    <>
                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}
                                <div className="row gy-4">
    <div className="col-lg-4">
        <div className="user-grid-card position-relative border radius-16 overflow-hidden bg-base h-100">
            <img
                src={imagePreview}
                alt=""
                className="w-100 h-10 object-fit-cover"
            />
            <div className="pb-24 ms-16 mb-24 me-16  mt--100">
                <div className="text-center border border-top-0 border-start-0 border-end-0">
                    <img
                        src={imagePreview}
                        alt=""
                        className="border br-white border-width-2-px w-200-px h-200-px rounded-circle object-fit-cover"
                    />
                    <h6 className="mb-0 mt-16">{user_data?.username}</h6>
                    <span className="text-secondary-light mb-16">{user_data?.email}</span>
                </div>
                <div className="mt-24">
                    <h6 className="text-xl mb-16">Personal Info</h6>
                    <ul>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                Full Name
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : {user_data?.username}
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                {" "}
                                Email
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : {user_data?.email}
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                {" "}
                                Phone Number
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : (+91) {user_data?.mobileNumber}
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                {" "}
                                Role
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : {user_data?.role}
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                {" "}
                                State
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : {user_data?.state}
                            </span>
                        </li>
                        <li className="d-flex align-items-center gap-1 mb-12">
                            <span className="w-30 text-md fw-semibold text-primary-light">
                                {" "}
                                city
                            </span>
                            <span className="w-70 text-secondary-light fw-medium">
                                : {user_data?.city}
                            </span>
                        </li>
              
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="col-lg-8">
        <div className="card h-100">
            <div className="card-body p-24">
                <ul
                    className="nav border-gradient-tab nav-pills mb-20 d-inline-flex"
                    id="pills-tab"
                    role="tablist"
                >
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link d-flex align-items-center px-24 active"
                            id="pills-edit-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-edit-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-edit-profile"
                            aria-selected="true"
                        >
                            Edit Profile
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link d-flex align-items-center px-24"
                            id="pills-change-passwork-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-change-passwork"
                            type="button"
                            role="tab"
                            aria-controls="pills-change-passwork"
                            aria-selected="false"
                            tabIndex={-1}
                        >
                            Change Password
                        </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                        <button
                            className="nav-link d-flex align-items-center px-24"
                            id="pills-notification-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-notification"
                            type="button"
                            role="tab"
                            aria-controls="pills-notification"
                            aria-selected="false"
                            tabIndex={-1}
                        >
                            Notification Settings
                        </button>
                    </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-edit-profile"
                        role="tabpanel"
                        aria-labelledby="pills-edit-profile-tab"
                        tabIndex={0}
                    >
                        <h6 className="text-md text-primary-light mb-16">Profile Image</h6>
                        {/* Upload Image Start */}
                        <div className="mb-24 mt-16">
                            <div className="avatar-upload">
                                <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                                    <input
                                        type="file"
                                        id="imageUpload"
                                        accept=".png, .jpg, .jpeg"
                                        hidden
                                        onChange={readURL}
                                    />
                                    <label
                                        htmlFor="imageUpload"
                                        className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle"
                                    >
                                        <Icon icon="solar:camera-outline" className="icon"></Icon>
                                    </label>
                                </div>
                                <div className="avatar-preview">
                                    <div
                                        id="imagePreview"
                                        style={{
                                            backgroundImage: `url(${imagePreview})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center'
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Upload Image End */}
                        <form action="#">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="username"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            Full Name
                                            <span className="text-danger-600">*</span>
                                        </label>
                                        <input
                            type="text"
                            className="form-control radius-8"
                            id="username"
                            value={inputData?.username}  
                            onChange={handlechange} 
                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="email"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            Email <span className="text-danger-600">*</span>
                                        </label>
                                        <input
                            type="email"
                            className="form-control radius-8"
                            id="email"
                            value={inputData?.email}  
                            onChange={handlechange} 
                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="mobileNumber"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            Phone
                                        </label>
                                        <input
                            type="text"
                            className="form-control radius-8"
                            id="mobileNumber"
                            value={inputData?.mobileNumber}  
                            onChange={handlechange} 
                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="role"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            Role
                                            <span className="text-danger-600">*</span>{" "}
                                        </label>
                                        {(current_user?.currentUser?.role == 'SuperAdmin' || current_user?.currentUser?.role == 'admin')?
                                        (        <select
                                            className="form-control radius-8 form-select"
                                            id="role"
                                            defaultValue="role"
                                            onChange={handlechange}

                                        >
                                            <option value="role" disabled>
                                                {user_data?.role}
                                            </option>
                                            {role?.map((data,index)=>(
                                           <option key={index} value={data?.role}>
                                           {data?.role}
                                         </option>
                                     ))}
                                        </select>):( 
                                                   <select
                                                   className="form-control radius-8 form-select"
                                                   id="role"
                                                   defaultValue="role"
                                                   onChange={handlechange}
       
                                               >
                                            <option value="role" disabled>
                                                {current_user.currentUser?.role}
                                            </option>
                                            </select>
                                            )}
                                
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="state"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            State
                                            <span className="text-danger-600">*</span>{" "}
                                        </label>
                                        <select
                                    className="form-control radius-8 form-select"
                                    id="state"
                                    defaultValue="Enter State"
                                    onChange={handlechange}

                                >
                                    <option value="Enter State" disabled>
                                        {user_data?.state}
                                    </option>
                                    <option value="Andhra Pradesh">Andhra Pradesh         </option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh </option>
                                    <option value="Assam">Assam </option>
                                    <option value="Bihar">Bihar </option>
                                    <option value="Chhattisgarh">Chhattisgarh </option>
                                    <option value="Goa">Goa </option>
                                    <option value="Gujarat">Gujarat </option>
                                    <option value="Jharkhand">Jharkhand </option>
                                    <option value="Karnataka">Karnataka </option>
                                    <option value="Kerala">Kerala </option>
                                    <option value="Madhya Pradesh">Madhya Pradesh </option>
                                    <option value="Maharashtra">Maharashtra </option>
                                    <option value="Odisha">Odisha </option>
                                    <option value="Rajasthan">Rajasthan </option>
                                    <option value="Tamil Nadu">Tamil Nadu </option>
                                    <option value="Telangana">Telangana </option>
                                    <option value="Uttar Pradesh">Uttar Pradesh </option>
                                    <option value="West Bengal">West Bengal </option>

                                </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="city"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            City <span className="text-danger-600">*</span>
                                        </label>
                                        <input
                            type="text"
                            className="form-control radius-8"
                            id="city"
                            value={inputData?.city}  
                            onChange={handlechange} 
                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-20">
                                        <label
                                            htmlFor="status"
                                            className="form-label fw-semibold text-primary-light text-sm mb-8"
                                        >
                                            status <span className="text-danger-600">*</span>
                                        </label>
                                        <input
                            type="text"
                            className="form-control radius-8"
                            id="status"
                            value={user_data?.status ? "Active" : "Inactive"}  
                            onChange={handlechange} 
                        /> 
                                    </div>
                                </div>     
                                <div className="col-sm-6">
    <div className="row">
        <div className="col-6">
            <div className="mb-20">
                <label
                    htmlFor="priortydays"
                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                    priorty days<span className="text-danger-600">*</span>
                </label>
                <select
                    className="form-control radius-8 form-select w-100"
                    id="slab1"
                    defaultValue="Select Role"
                    onChange={handlechange}
                >
                    <option value="Select Role" disabled>
                        Select Slab 1
                    </option>
                    <option value="1-30">1 30</option>
                    <option value="31-60">31 60</option>
                    <option value="61-90">61 90</option>
                    <option value="91-120">91 120</option>
                    <option value="121-180">121 180</option>
                    <option value="181-365">181 365</option>
                    <option value="365">365</option>
                </select>
            </div>
        </div>
        <div className="col-6">
            <div className="mb-20">
                <label
                    htmlFor="priortydays"
                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                >
                    priorty days<span className="text-danger-600">*</span>
                </label>
                <select
                    className="form-control radius-8 form-select w-100"
                    id="slab2"
                    defaultValue="Select Role"
                    onChange={handlechange}
                >
                    <option value="Select Role" disabled>
                        Select Slab 2
                    </option>
                    <option value="0">0 0</option>
                    <option value="1-30">1 30</option>
                    <option value="31-60">31 60</option>
                    <option value="61-90">61 90</option>
                    <option value="91-120">91 120</option>
                    <option value="121-180">121 180</option>
                    <option value="181-365">181 365</option>
                    <option value="365">365</option>
                </select>
            </div>
        </div>
    </div>
</div>

{(current_user?.currentUser?.role === 'SuperAdmin' || current_user?.currentUser?.role === 'admin') && (
  <>
    <div className="col-sm-6">
      <div className="mb-20">
        <label htmlFor="service" className="form-label fw-semibold text-primary-light text-sm mb-8">
          Service deduction %
          <span className="text-danger-600">*</span>
        </label>
        <select
          className="form-control radius-8 form-select"
          id="service"
          defaultValue="role"
          onChange={handlechange}
        >
          <option value="role" disabled>
            Select %
          </option>
          <option value="0">0</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>

    <div className="col-sm-6">
      <div className="mb-20">
        <label htmlFor="DailyLate" className="form-label fw-semibold text-primary-light text-sm mb-8">
          Daily late fee deduction %
          <span className="text-danger-600">*</span>
        </label>
        <select
          className="form-control radius-8 form-select"
          id="DailyLate"
          defaultValue="role"
          onChange={handlechange}
        >
          <option value="role" disabled>
            Select %
          </option>
          <option value="0">0</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
        </select>
      </div>
    </div>
  </>
)}

                            </div>
                            <div className="d-flex align-items-center justify-content-center gap-3">
                                {/* <button
                                    type="button"
                                    className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                                >
                                    Cancel
                                </button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                                    onClick={handleSubmit}
                                >
                                                {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                "Save"
            )}
            {loading && " Loading..."}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="tab-pane fade" id="pills-change-passwork" role="tabpanel" aria-labelledby="pills-change-passwork-tab" tabIndex="0">
                        <div className="mb-20">
                            <label htmlFor="your-password" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                New Password <span className="text-danger-600">*</span>
                            </label>
                            <div className="position-relative">
                                <input
                                onChange={handlepassword}
                                    type={passwordVisible ? "text" : "password"}
                                    className="form-control radius-8"
                                    id="password"
                                    placeholder="Enter New Password*"
                                />
                                <span
                                    className={`toggle-password ${passwordVisible ? "ri-eye-off-line" : "ri-eye-line"} cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light`}
                                    onClick={togglePasswordVisibility}
                                ></span>
                            </div>
                            
                        </div>
                        

                        <div className="mb-20">
                            <label htmlFor="confirm-password" className="form-label fw-semibold text-primary-light text-sm mb-8">
                                Confirm Password <span className="text-danger-600">*</span>
                            </label>
                            <div className="position-relative">
                                <input
                                 onChange={handlepassword}

                                    type={confirmPasswordVisible ? "text" : "password"}
                                    className="form-control radius-8"
                                    id="confirmPassword"
                                    placeholder="Confirm Password*"
                                />
                                <span
                                    className={`toggle-password ${confirmPasswordVisible ? "ri-eye-off-line" : "ri-eye-line"} cursor-pointer position-absolute end-0 top-50 translate-middle-y me-16 text-secondary-light`}
                                    onClick={toggleConfirmPasswordVisibility}
                                ></span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center gap-3">
                                {/* <button
                                    type="button"
                                    className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                                >
                                    Cancel
                                </button> */}
                                <button
                                    type="button"
                                    onClick={handlepassSubmit}

                                    className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                                  
                                >
                                    Save
                                </button>

                            </div>
                    </div>
                    {/* <div
                        className="tab-pane fade"
                        id="pills-notification"
                        role="tabpanel"
                        aria-labelledby="pills-notification-tab"
                        tabIndex={0}
                    >
                        <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                            <label
                                htmlFor="companzNew"
                                className="position-absolute w-100 h-100 start-0 top-0"
                            />
                            <div className="d-flex align-items-center gap-3 justify-content-between">
                                <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                                    Company News
                                </span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="companzNew"
                                />
                            </div>
                        </div>
                        <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                            <label
                                htmlFor="pushNotifcation"
                                className="position-absolute w-100 h-100 start-0 top-0"
                            />
                            <div className="d-flex align-items-center gap-3 justify-content-between">
                                <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                                    Push Notification
                                </span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="pushNotifcation"
                                    defaultChecked=""
                                />
                            </div>
                        </div>
                        <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                            <label
                                htmlFor="weeklyLetters"
                                className="position-absolute w-100 h-100 start-0 top-0"
                            />
                            <div className="d-flex align-items-center gap-3 justify-content-between">
                                <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                                    Weekly News Letters
                                </span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="weeklyLetters"
                                    defaultChecked=""
                                />
                            </div>
                        </div>
                        <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                            <label
                                htmlFor="meetUp"
                                className="position-absolute w-100 h-100 start-0 top-0"
                            />
                            <div className="d-flex align-items-center gap-3 justify-content-between">
                                <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                                    Meetups Near you
                                </span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="meetUp"
                                />
                            </div>
                        </div>
                        <div className="form-switch switch-primary py-12 px-16 border radius-8 position-relative mb-16">
                            <label
                                htmlFor="orderNotification"
                                className="position-absolute w-100 h-100 start-0 top-0"
                            />
                            <div className="d-flex align-items-center gap-3 justify-content-between">
                                <span className="form-check-label line-height-1 fw-medium text-secondary-light">
                                    Orders Notifications
                                </span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="orderNotification"
                                    defaultChecked=""
                                />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </div>
</div>
</>
  )
}

export default ViewProfileLayer