import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';  // Import the configuration object
import FailedMessage from './FailedMessage'

const AddAgentList = () => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const [formData, setFormData] = useState({});
    const [message, setMessage] = useState('');
    const [loading,setLoading] = useState(false)

    const user_data = useSelector((state) => state?.user?.currentUser?._id)


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);

            };
            reader.readAsDataURL(file);

        }
    };
 
    const handlSubmit = async(e)=>{
        e.preventDefault()
        const token = localStorage.getItem('access_token')
        setLoading(true)

        formData.profilePicture = imagePreviewUrl
        formData.created = user_data

        const dataFetch = await fetch(`${config.apiUrl}signup `,{
            method: 'POST',
            headers: {
                'Authorization': token,  // Replace with your actual token
                'Content-Type': 'application/json'  // Include this if you're sending JSON data
              },
                          body: JSON.stringify(formData),
                    })
                    const json_data =  await dataFetch.json()
                    setMessage(json_data.message)
                    if(dataFetch.ok){
                        window.location.reload()

                     setLoading(false)
                    }

    }
    
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
    useEffect(()=>{
    },[imagePreviewUrl])
    const handleHideMessage = () =>     setMessage(''); // Clear the message when the alert hides


  return (
    <div className="card h-100 p-0 radius-12">
    <div className="card-body p-24">
        <div className="row justify-content-center">
            <div className="col-xxl-6 col-xl-8 col-lg-10">
                <div className="card border">
                    <div className="card-body">
                        <h6 className="text-md text-primary-light mb-16">Profile Image</h6>
                        {/* Upload Image Start */}
                        <div className="mb-24 mt-16">
                            <div className="avatar-upload">
                                <div className="avatar-edit position-absolute bottom-0 end-0 me-24 mt-16 z-1 cursor-pointer">
                                    <input
                                        type="file"
                                        id="imageUpload"
                                        accept=".png, .jpg, .jpeg"
                                        hidden
                                        onChange={handleImageChange}
                                    />
                                    <label
                                        htmlFor="imageUpload"
                                        className="w-32-px h-32-px d-flex justify-content-center align-items-center bg-primary-50 text-primary-600 border border-primary-600 bg-hover-primary-100 text-lg rounded-circle">
                                        <Icon icon="solar:camera-outline" className="icon"></Icon>
                                    </label>
                                </div>
                                <div className="avatar-preview">
                                    <div
                                        id="imagePreview"
                                        style={{
                                            backgroundImage: imagePreviewUrl ? `url(${imagePreviewUrl})` : '',

                                        }}
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Upload Image End */}
                        <form action="#">
                            <div className="mb-20">
                                <label
                                    htmlFor="username"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    Full Name <span className="text-danger-600">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control radius-8"
                                    id="username"
                                    placeholder="Enter Full Name"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="mb-20">
                                <label
                                    htmlFor="email"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    Email <span className="text-danger-600">*</span>
                                </label>
                                <input
                                    type="email"
                                    className="form-control radius-8"
                                    id="email"
                                    placeholder="Enter email address"
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="mb-20">
                                <label
                                    htmlFor="number"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    Phone
                                </label>
                                <input
                                    type="number"
                                    className="form-control radius-8"
                                    id="mobileNumber"
                                    placeholder="Enter phone number"
                                    onChange={handleChange}

                               />
                            </div>
                            <div className="mb-20">
                                <label
                                    htmlFor="role"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    Role
                                    <span className="text-danger-600">*</span>{" "}
                                </label>
                                <select
                                    className="form-control radius-8 form-select"
                                    id="role"
                                    defaultValue="Select Role"
                                    onChange={handleChange}

                                >
                                    <option value="Select Role" disabled>
                                        Select Role
                                    </option>

                                    <option value="StucredAgent">StucredAgent</option>


                                </select>
                            </div>
                            <div className="mb-20">
                                <label
                                    htmlFor="state"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    State
                                    <span className="text-danger-600">*</span>{" "}
                                </label>
                                <select
                                    className="form-control radius-8 form-select"
                                    id="state"
                                    defaultValue="Enter State"
                                    onChange={handleChange}

                                >
                                    <option value="Enter State" disabled>
                                        Enter State
                                    </option>
                                    <option value="Andhra Pradesh">Andhra Pradesh         </option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh </option>
                                    <option value="Assam">Assam </option>
                                    <option value="Bihar">Bihar </option>
                                    <option value="Chhattisgarh">Chhattisgarh </option>
                                    <option value="Goa">Goa </option>
                                    <option value="Gujarat">Gujarat </option>
                                    <option value="Jharkhand">Jharkhand </option>
                                    <option value="Karnataka">Karnataka </option>
                                    <option value="Kerala">Kerala </option>
                                    <option value="Madhya Pradesh">Madhya Pradesh </option>
                                    <option value="Maharashtra">Maharashtra </option>
                                    <option value="Odisha">Odisha </option>
                                    <option value="Rajasthan">Rajasthan </option>
                                    <option value="Tamil Nadu">Tamil Nadu </option>
                                    <option value="Telangana">Telangana </option>
                                    <option value="Uttar Pradesh">Uttar Pradesh </option>
                                    <option value="West Bengal">West Bengal </option>

                                </select>
                            </div>
                            <div className="mb-20">
                                <label
                                    htmlFor="city"
                                    className="form-label fw-semibold text-primary-light text-sm mb-8"
                                >
                                    City
                                </label>
                                <input
                                    type="city"
                                    className="form-control radius-8"
                                    id="city"
                                    placeholder="Enter City"
                                    onChange={handleChange}

                                />
                            </div>
            
                            <div className="d-flex align-items-center justify-content-center gap-3">
                                <button
                                    type="button"
                                    className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-56 py-11 radius-8"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary border border-primary-600 text-md px-56 py-12 radius-8"
                                    onClick={handlSubmit}
                                >
                               {loading ? (
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            ) : (
                "Save"
            )}
            {loading && " Loading..."}
        </button>
                                {message && <FailedMessage message={message} onHide={handleHideMessage} />}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  )
}

export default AddAgentList