import React, { useEffect, useState } from 'react'
import useReactApexChart from '../../hook/useReactApexChart'
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import config from '../../config';
import { Loader } from './Loader';

ChartJS.register(ArcElement, Tooltip, Legend);
const UserActivatesTwo = () => {
  const [lablesoption, setlabaleoptions] = useState('')
  const [dataOriginal, setDataOriginal] = useState({data1: 0, data2:0})
  const [labeldata,setlabeldata] = useState('')
  let data =  {
    labels: lablesoption,
    datasets: [
      {
        label: "loan amount",
        data: labeldata, // Example values
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",  // Sales
          "rgba(54, 162, 235, 0.6)",  // Marketing
          "rgba(75, 192, 192, 0.6)",  // Development
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
let options =  {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
  };
    
    let [selectedOptions, setSelectedOptions] = useState('1-30')
    const [chartData,setchartData] = useState({});
    const [loading, setLoading] = useState(false); // Loading state

    const handlerSelectedchanges = (event)=>{
      const value = event.target.value;
      setSelectedOptions(value)
    }
    async function fetchData() {
      // if(typeof(selectedOptions) == 'string'){
      //   selectedOptions = new Date().getFullYear()
      const token = localStorage.getItem('access_token')
      // }
   
      try {
        const response = await fetch(`${config.apiUrl}slab_chart?slab=${selectedOptions}`,{
          method: "GET",
          headers:{
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          }
        });
        const json_data = await response.json();
        setlabeldata(json_data.data.formatted)
        setlabaleoptions(json_data.data.slab)
        setDataOriginal({data1: json_data.data.original, data2: json_data.data1.original})
        // setchartData({
        //   labels: json_data.categories,
        //   datasets: [
        //     {
        //       label: 'Total Loan Amounts',
        //       data: json_data.data,
        //       borderColor: 'rgba(75, 192, 192, 1)',
        //       backgroundColor: 'rgba(75, 192, 192, 0.2)',
        //       tension: 0.4, // Curve of the line
        //     },
        //   ],
        // });
        setLoading(false);  // Data has been loaded

      } catch (error) {
        console.error('Error fetching data:', error);

        setLoading(false);  // Data has been loaded

      }
    }

    useEffect(() => {
      fetchData()
    }, [selectedOptions]);


  return (
    loading ? (
<Loader loading={loading}/>    ) : (
    <div className="col-xxl-3 ml-10">
    <div className="card h-100 radius-8 border-0">
        <div className="card-body p-24">
            <h6 className="mb-2 fw-bold text-lg">Slab</h6>
            <div className="d-flex align-items-center flex-wrap gap-3 mt-10 mb-10">
                        <select value={selectedOptions}
        onChange={handlerSelectedchanges}  className="form-select form-select-sm w-auto bg-base border text-secondary-light" defaultValue="">
                                <option value="" disabled>
                                    Slab
                                </option>
                                <option value="1-30">1 to 30 - 31 to 60</option>
                                <option value="61-90">61 to 90 - 91 to 120</option>
                                <option value="121-150">121 to 150 - 1 to 180</option>
                                <option value="181-365">181 to 365 - 365++</option>
                            </select>
                        </div>
            <div className="position-relative">
                <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-bold text-xxl d-flex justify-content-center align-items-center rounded-circle position-absolute end-0 top-0 z-1">
                  {`${labeldata[0]}`}
                </span>
                <Pie data={data} options={options}  />

                <span className="w-80-px h-80-px bg-base shadow text-primary-light fw-bold text-xxl d-flex justify-content-center align-items-center rounded-circle position-absolute start-3 bottom-0 z-1">
                {`${labeldata[1]}`}
                </span>
            </div>
            <ul className="d-flex flex-wrap flex-column mt-64 gap-3">
                <li className="d-flex align-items-center gap-2">
                    <span className="w-16-px h-16-px radius-2 bg-primary-600" />
                    <span className="text-secondary-light text-lg fw-normal">
                        Slab {lablesoption[0]}:
                        <span className="text-primary-light fw-bold text-lg">
                           {dataOriginal.data1}
                        </span>
                    </span>
                </li>
                <li className="d-flex align-items-center gap-2">
                    <span className="w-16-px h-16-px radius-2 bg-yellow" />
                    <span className="text-secondary-light text-lg fw-normal">
                        Slab {lablesoption[1]}:
                        <span className="text-primary-light fw-bold text-lg">
                        {dataOriginal.data2}
                        </span>
                    </span>
                </li>
            </ul>
        </div>
    </div>
</div>
    )
  )
}

export default UserActivatesTwo