import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ThemeToggleButton from "../helper/ThemeToggleButton";
import { useDispatch, useSelector } from "react-redux";
import { signoutSuccess } from "../redux/user/userSlice";
import config from "../config";
const StucredLayout = ({ children }) => {
  let [sidebarActive, seSidebarActive] = useState(false);
  let [mobileMenu, setMobileMenu] = useState(false);
  let [ActiveLink,setActiveLink] = useState()
  const location = useLocation(); // Hook to get the current route
  const user_data = useSelector((state)=> state.user.currentUser)
  const individual = useSelector((state)=>  state.Individual.individual_role)

  const handleLinkClick = (link) => {
    setActiveLink(link);
  }
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const logout = async(e)=>{
      e.preventDefault();//j
      const data = await fetch(`${config.apiUrl}signout`,{
        method: "POST",
        headers: { 'Content-Type': 'application/json' }
  
      })
  
  

      const jsonData = await data.json()
      if(jsonData.success == true){
        localStorage.removeItem("access_token")
        dispatch(signoutSuccess())
        navigate("/")
  
      }
    }
  useEffect(() => {
    // Function to handle dropdown clicks
    const handleDropdownClick = (event) => {
      event.preventDefault();
      const clickedLink = event.currentTarget;
      const clickedDropdown = clickedLink.closest('.dropdown');

      if (!clickedDropdown) return;

      const isActive = clickedDropdown.classList.contains('open');

      // Close all dropdowns
      const allDropdowns = document.querySelectorAll('.sidebar-menu .dropdown');
      allDropdowns.forEach((dropdown) => {
        dropdown.classList.remove('open');
      });

      // Toggle the clicked dropdown
      if (!isActive) {
        clickedDropdown.classList.add('open');
      }
    };

    // Attach click event listeners to all dropdown triggers
    const dropdownTriggers = document.querySelectorAll('.sidebar-menu .dropdown > a, .sidebar-menu .dropdown > Link');

    dropdownTriggers.forEach((trigger) => {
      trigger.addEventListener('click', handleDropdownClick);
    });

    // Function to open submenu based on current route
    const openActiveDropdown = () => {
      const allDropdowns = document.querySelectorAll('.sidebar-menu .dropdown');
      allDropdowns.forEach((dropdown) => {
        const submenuLinks = dropdown.querySelectorAll('.sidebar-submenu li a');
        submenuLinks.forEach((link) => {
          if (link.getAttribute('href') === location.pathname || link.getAttribute('to') === location.pathname) {
            dropdown.classList.add('open');
          }
        });
      });
    };

    // Open the submenu that contains the open route
    openActiveDropdown();



    // Cleanup event listeners on unmount
    return () => {
      dropdownTriggers.forEach((trigger) => {
        trigger.removeEventListener('click', handleDropdownClick);
      });

    };
  }, [location.pathname]);


  let sidebarControl = () => {
    seSidebarActive(!sidebarActive);
  };

  let mobileMenuControl = () => {
    setMobileMenu(!mobileMenu);
  };
  
  return (
    <section className={mobileMenu ? "overlay active" : "overlay "}>
    {/* sidebar */}
    <aside className={sidebarActive ? "sidebar active " : mobileMenu ? "sidebar sidebar-open" : "sidebar"}>
      <button onClick={mobileMenuControl} type="button" className="sidebar-close-btn">
        <Icon icon="radix-icons:cross-2" />
      </button>
      <div>
        <Link to="/" className="sidebar-logo">
   
          <img
            src="assets/images/stpng.png"
            alt="site logo"
            className="light-logo"
          />

          <img
            src="assets/images/stpng.png"
            alt="site logo"
            className="dark-logo"
          />
          <img
            src="assets/images/stpng.png"
            alt="site logo"
            className="logo-icon"
          />
        </Link>
      </div>
      <div className="sidebar-menu-area">
        <ul className="sidebar-menu" id="sidebar-menu">
          {/* <li className="dropdown">
            <Link to="#">
              <Icon icon="solar:home-smile-angle-outline" className="menu-icon" />
              <span>Stucred</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
                {individual?.inhouse_dashboard?.restrict == true && (
                <NavLink to="/stucred_dashboard" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-warning-main w-auto" /> Inhouse Dashboard
                </NavLink>)}

              </li>
             
       
            </ul>
          </li> */}

          <li className="sidebar-menu-group-title">Manage Loans</li>
          <li>
          {individual?.loan_details?.restrict == true && (

            <NavLink to="/stucred_loan" className={(navData) =>
              navData.isActive ? "active-page" : ""
            }>
              <Icon icon="mage:email" className="menu-icon" />
              <span>Loan Details</span>
            </NavLink>
          )}
          </li>
          

          <li className="sidebar-menu-group-title">Manage User</li>

          {/* Users Dropdown */}
          <li className="dropdown">
            <Link to="#">
              <Icon icon="flowbite:users-group-outline" className="menu-icon" />
              <span>Users</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
              {individual?.view_profile?.restrict == true && (

                <NavLink to={`/stucred_profile?${user_data?._id}`} className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-danger-main w-auto" /> View Profile
                </NavLink>
              )}
              </li>
            </ul>
          </li>

          {/* Role & Access Dropdown */}
          

          {/* <li className="sidebar-menu-group-title">Terms & Policy</li> */}

          {/* Authentication Dropdown */}
  

{/*  
         
          <li>
            <NavLink to="/faq" className={(navData) =>
              navData.isActive ? "active-page" : ""
            }>
              <Icon icon="mage:message-question-mark-round" className="menu-icon" />
              <span>FAQs.</span>
            </NavLink>
          </li>
     
          <li>
            <NavLink to="/terms-condition" className={(navData) =>
              navData.isActive ? "active-page" : ""
            }>
              <Icon icon="octicon:info-24" className="menu-icon" />
              <span>Terms &amp; Conditions</span>
            </NavLink>
          </li> */}

          {/* Settings Dropdown */}
          {/* <li className="dropdown">
            <Link to="#">
              <Icon icon="icon-park-outline:setting-two" className="menu-icon" />
              <span>Settings</span>
            </Link>
            <ul className="sidebar-submenu">
              <li>
                <NavLink to="/company" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-primary-600 w-auto" /> Company
                </NavLink>
              </li>
              <li>
                <NavLink to="/notification" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-warning-main w-auto" /> Notification
                </NavLink>
              </li>
              <li>
                <NavLink to="/notification-alert" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-info-main w-auto" /> Notification Alert
                </NavLink>
              </li>
              <li>
                <NavLink to="/theme" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-danger-main w-auto" /> Theme
                </NavLink>
              </li>
              <li>
                <NavLink to="/currencies" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-danger-main w-auto" /> Currencies
                </NavLink>
              </li>
              <li>
                <NavLink to="/language" className={(navData) =>
                  navData.isActive ? "active-page" : ""
                }>
                  <i className="ri-circle-fill circle-icon text-danger-main w-auto" /> Languages
                </NavLink>
              </li>
        
            </ul>
          </li> */}
        </ul>
      </div>
    </aside>

    <main className={sidebarActive ? "dashboard-main active" : "dashboard-main"}>
      <div className="navbar-header">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <div className="d-flex flex-wrap align-items-center gap-4">
              <button type="button" className="sidebar-toggle" onClick={sidebarControl}>
                {
                  sidebarActive ? (<Icon
                    icon="iconoir:arrow-right"
                    className="icon text-2xl non-active"
                  />) : (<Icon
                    icon="heroicons:bars-3-solid"
                    className="icon text-2xl non-active "
                  />)
                }
              </button>
              <button onClick={mobileMenuControl} type="button" className="sidebar-mobile-toggle">
                <Icon
                  icon="heroicons:bars-3-solid"
                  className="icon"
                />
              </button>
              <form className="navbar-search">
                <input type="text" name="search" placeholder="Search" />
                <Icon icon="ion:search-outline" className="icon" />
              </form>
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex flex-wrap align-items-center gap-3">
              {/* ThemeToggleButton */}
              <ThemeToggleButton />
      
              {/* Language dropdown end */}
              {/* <div className="dropdown">
                <button
                  className="has-indicator w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center "
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  <Icon
                    icon="mage:email"
                    className="text-primary-light text-xl"
                  />
                </button>
                <div className="dropdown-menu to-top dropdown-menu-lg p-0">
                  <div className="m-16 py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                    <div>
                      <h6 className="text-lg text-primary-light fw-semibold mb-0">
                        Message
                      </h6>
                    </div>
                    <span className="text-primary-600 fw-semibold text-lg w-40-px h-40-px rounded-circle bg-base d-flex justify-content-center align-items-center">
                      05
                    </span>
                  </div>
                  <div className="max-h-400-px overflow-y-auto scroll-sm pe-4">
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-40-px h-40-px rounded-circle flex-shrink-0 position-relative">
                          <img
                            src="assets/images/notification/profile-3.png"
                            alt=""
                          />
                          <span className="w-8-px h-8-px bg-success-main rounded-circle position-absolute end-0 bottom-0" />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Kathryn Murphy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-100-px">
                            hey! there i’m...
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-sm text-secondary-light flex-shrink-0">
                          12:30 PM
                        </span>
                        <span className="mt-4 text-xs text-base w-16-px h-16-px d-flex justify-content-center align-items-center bg-warning-main rounded-circle">
                          8
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-40-px h-40-px rounded-circle flex-shrink-0 position-relative">
                          <img
                            src="assets/images/notification/profile-4.png"
                            alt=""
                          />
                          <span className="w-8-px h-8-px  bg-neutral-300 rounded-circle position-absolute end-0 bottom-0" />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Kathryn Murphy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-100-px">
                            hey! there i’m...
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-sm text-secondary-light flex-shrink-0">
                          12:30 PM
                        </span>
                        <span className="mt-4 text-xs text-base w-16-px h-16-px d-flex justify-content-center align-items-center bg-warning-main rounded-circle">
                          2
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-40-px h-40-px rounded-circle flex-shrink-0 position-relative">
                          <img
                            src="assets/images/notification/profile-5.png"
                            alt=""
                          />
                          <span className="w-8-px h-8-px bg-success-main rounded-circle position-absolute end-0 bottom-0" />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Kathryn Murphy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-100-px">
                            hey! there i’m...
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-sm text-secondary-light flex-shrink-0">
                          12:30 PM
                        </span>
                        <span className="mt-4 text-xs text-base w-16-px h-16-px d-flex justify-content-center align-items-center bg-neutral-400 rounded-circle">
                          0
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-40-px h-40-px rounded-circle flex-shrink-0 position-relative">
                          <img
                            src="assets/images/notification/profile-6.png"
                            alt=""
                          />
                          <span className="w-8-px h-8-px bg-neutral-300 rounded-circle position-absolute end-0 bottom-0" />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Kathryn Murphy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-100-px">
                            hey! there i’m...
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-sm text-secondary-light flex-shrink-0">
                          12:30 PM
                        </span>
                        <span className="mt-4 text-xs text-base w-16-px h-16-px d-flex justify-content-center align-items-center bg-neutral-400 rounded-circle">
                          0
                        </span>
                      </div>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-40-px h-40-px rounded-circle flex-shrink-0 position-relative">
                          <img
                            src="assets/images/notification/profile-7.png"
                            alt=""
                          />
                          <span className="w-8-px h-8-px bg-success-main rounded-circle position-absolute end-0 bottom-0" />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Kathryn Murphy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-100-px">
                            hey! there i’m...
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-end">
                        <span className="text-sm text-secondary-light flex-shrink-0">
                          12:30 PM
                        </span>
                        <span className="mt-4 text-xs text-base w-16-px h-16-px d-flex justify-content-center align-items-center bg-warning-main rounded-circle">
                          8
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="text-center py-12 px-16">
                    <Link
                      to="#"
                      className="text-primary-600 fw-semibold text-md"
                    >
                      See All Message
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* Message dropdown end */}
              {/* <div className="dropdown">
                <button
                  className="has-indicator w-40-px h-40-px bg-neutral-200 rounded-circle d-flex justify-content-center align-items-center"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  <Icon
                    icon="iconoir:bell"
                    className="text-primary-light text-xl"
                  />
                </button>
                <div className="dropdown-menu to-top dropdown-menu-lg p-0">
                  <div className="m-16 py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                    <div>
                      <h6 className="text-lg text-primary-light fw-semibold mb-0">
                        Notifications
                      </h6>
                    </div>
                    <span className="text-primary-600 fw-semibold text-lg w-40-px h-40-px rounded-circle bg-base d-flex justify-content-center align-items-center">
                      05
                    </span>
                  </div>
                  <div className="max-h-400-px overflow-y-auto scroll-sm pe-4">
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                          <Icon
                            icon="bitcoin-icons:verify-outline"
                            className="icon text-xxl"
                          />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Congratulations
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                            Your profile has been Verified. Your profile has
                            been Verified
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-secondary-light flex-shrink-0">
                        23 Mins ago
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                          <img
                            src="assets/images/notification/profile-1.png"
                            alt=""
                          />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Ronald Richards
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                            You can stitch between artboards
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-secondary-light flex-shrink-0">
                        23 Mins ago
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-44-px h-44-px bg-info-subtle text-info-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                          AM
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Arlene McCoy
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                            Invite you to prototyping
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-secondary-light flex-shrink-0">
                        23 Mins ago
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between bg-neutral-50"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-44-px h-44-px bg-success-subtle text-success-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                          <img
                            src="assets/images/notification/profile-2.png"
                            alt=""
                          />
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Annette Black
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                            Invite you to prototyping
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-secondary-light flex-shrink-0">
                        23 Mins ago
                      </span>
                    </Link>
                    <Link
                      to="#"
                      className="px-24 py-12 d-flex align-items-start gap-3 mb-2 justify-content-between"
                    >
                      <div className="text-black hover-bg-transparent hover-text-primary d-flex align-items-center gap-3">
                        <span className="w-44-px h-44-px bg-info-subtle text-info-main rounded-circle d-flex justify-content-center align-items-center flex-shrink-0">
                          DR
                        </span>
                        <div>
                          <h6 className="text-md fw-semibold mb-4">
                            Darlene Robertson
                          </h6>
                          <p className="mb-0 text-sm text-secondary-light text-w-200-px">
                            Invite you to prototyping
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-secondary-light flex-shrink-0">
                        23 Mins ago
                      </span>
                    </Link>
                  </div>
                  <div className="text-center py-12 px-16">
                    <Link
                      to="#"
                      className="text-primary-600 fw-semibold text-md"
                    >
                      See All Notification
                    </Link>
                  </div>
                </div>
              </div> */}
              {/* Notification dropdown end */}
              <div className="dropdown">
                <button
                  className="d-flex justify-content-center align-items-center rounded-circle"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={user_data?.profilePicture}
                    alt="image_user"
                    className="w-40-px h-40-px object-fit-cover rounded-circle"
                  />
                </button>
                <div className="dropdown-menu to-top dropdown-menu-sm">
                  <div className="py-12 px-16 radius-8 bg-primary-50 mb-16 d-flex align-items-center justify-content-between gap-2">
                    <div>
                      <h6 className="text-lg text-primary-light fw-semibold mb-2">
                        {user_data.username}                      </h6>
                      <span className="text-secondary-light fw-medium text-sm">{user_data?.role}</span>
                    </div>
                    <button type="button" className="hover-text-danger">
                      <Icon icon="radix-icons:cross-1" className="icon text-xl" />
                    </button>
                  </div>
                  <ul className="to-top-list">
    
                  <li>
                    <Link className="dropdown-item text-black px-0 py-8 hover-bg-transparent hover-text-danger d-flex align-items-center gap-3"
to="#"
onClick={(e) => {
  handleLinkClick('LOGOUT');
  logout(e);
}}
>

                        <Icon icon="lucide:power" className="icon text-xl"  /> Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Profile dropdown end */}

            </div>
          </div>
        </div>
      </div>

      {/* dashboard-main-body */}
      <div className="dashboard-main-body">{children}</div>

      {/* Footer section */}
      <footer className="d-footer">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <p className="mb-0">© 2024 Stucred. All Rights Reserved.</p>
          </div>
          <div className="col-auto">
            <p className="mb-0">
              Made by <span className="text-primary-600">Stucred</span>
            </p>
          </div>
        </div>
      </footer>
    </main>
  </section>
  )
}

export default StucredLayout