import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import 'datatables.net-dt/js/dataTables.dataTables.js';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link } from 'react-router-dom';
import InputDialog from './InputDialog';
import { useSelector } from 'react-redux';
import config from '../../config';
import { DateRangePicker } from 'react-date-range';
import ModalEditRemarks from './ModalEditRemarks';
import ModalLoan from './ModalLoan';
import * as XLSX from 'xlsx';  // Library for handling Excel file parsing


const AgencyForm1 = () => {
    const [data, setData] = useState()
    const[loan, setLoan] = useState('')
    const [loan_data, setloanData] = useState()
    const [percentage, setPercentage] = useState()
        const [currentPage, setCurrentPage] = useState(1)
            const [totalPages , setTotalPages] = useState()
                const [selection , setSelection] = useState()
                const [startDate, setStartDate] = useState('');
                const [endDate, setEndDate] = useState('');
                          const [loadingbutton, setloadingbutton] = useState()
                          const [error, setError] = useState(null);
                           const [loading, setLoading] = useState(true); // Loading state
            
                const [selectedOptions, setSelectedOptions] = useState('')
                const [agency, setAgency] = useState()
                const [showCustomRange, setShowCustomRange] = useState(false);
                const [start,setstart] = useState()
                const [end,setend] = useState()
                const [formData, setFormData] = useState({});
               const current_user = useSelector((state)=> state?.user?.currentUser?._id)
               const user_data = useSelector((state)=> state?.user?.currentUser)
               const [remarks, setRemarks] = useState('');
               const [remarksStatus, setRemarksStatus] = useState(false); 
            const [AgencySelection, setAgencySelection] = useState('')
                  const [cog_id, setCog_id] = useState()
                
                                          const [showModal, setShowModal] = useState(false); // Controls modal visibility
                                          const [showModal1, setShowModal1] = useState(false); // Controls modal visibility
              const [loan_no, setLoan_no] = useState('')
            
                                          const [selectedLoanId, setSelectedLoanId] = useState(null); // Stores the selected loan_id
            
        const [selectedOption, setSelectedOption] = useState('');
        
    
    const individual = useSelector((state)=>  state.Individual.individual_role)

    const serviceSelect = useRef(null);
    const dailySelect = useRef(null);
    const [openDate, setOpenDate] = useState(false)
      const [selectionRange, setSelectionRange] = useState({
        startDate: '',
        endDate: '',
        key: "selection",
      });
        const [showCalendar, setShowCalendar] = useState(false); // State to toggle calendar visibility
      

        const handleShow = (loanId, currentRemarks, currentRemarksStatus) => {
          setSelectedLoanId(loanId);
          setRemarks(currentRemarks);
          setRemarksStatus(currentRemarksStatus);
          setShowModal(true); // Show modal
        };
            const fetchDataForPage = async (page) => {
                         const token = localStorage.getItem('access_token')
                   
                         try {
                           const response = await fetch(`${config.apiUrl}mapped_collection/${user_id._id}?${selectedOptions}=${formData.LoanId}&account_type=${formData.accountType}&${selectedOptions}start=${new Date(selectionRange.startDate).toLocaleDateString()}&${selectedOptions}end=${new Date(selectionRange.endDate).toLocaleDateString()}&${selectedOptions}=${AgencySelection}&slab=${selectedOption}&startslab=${start}&endslab=${end}&loanMin=${formData.loanMin}&loanMax=${formData.loanMax}&pages=${page}&excel=true`, {
                             headers: {
                               'Authorization': token,
                               'Content-Type': 'application/json',
                             },
                           });
                     
                           if (!response.ok) {
                             throw new Error('Failed to fetch data');
                           }
                     
                           const data = await response.json();
                   
                           return data;  // Assuming data has the structure { data: [...], totalPages: ... }conso
                         } catch (error) {
                           console.error(error);
                           setError(error.message);
                           return null;
                         }
                       };
                          const spinnerStyle = {
                          border: '4px solid rgba(255, 255, 255, 0.3)',
                          borderTop: '4px solid #3498db',
                          borderRadius: '50%',
                          width: '24px',
                          height: '24px',
                          animation: 'spin 1s linear infinite',
                          marginRight: '8px', // Space between spinner and text
                        };
                      
                        // Inline styles for the button when loading
                        const loadingTextStyle = {
                          display: 'flex',
                          alignItems: 'center',
                        };
                      
                        // Keyframes for the spinning animation (defined in JS)
                        const styleSheet = document.styleSheets[0];
                        styleSheet.insertRule(`
                          @keyframes spin {
                            0% { transform: rotate(0deg); }
                            100% { transform: rotate(360deg); }
                          }
                        `, styleSheet.cssRules.length);
                    
                        // Function to handle exporting all data
                        const handleExport = async () => {
                          setloadingbutton(true);
                          let allData = [];
                          let totalPages = 0;
                      
                          try {
                            // First, get the data from the first page
                            const firstPageData = await fetchDataForPage(1);
                            if (firstPageData) {
                              totalPages = firstPageData.totalPages || 1;
                              allData = firstPageData.data;
                            }
                      
                            // If there are more pages, fetch the remaining pages
                            for (let page = 2; page <= totalPages; page++) {
                              const pageData = await fetchDataForPage(page);
                              if (pageData) {
                                allData = [...allData, ...pageData.data];
                              }
                            }
                            console.log(allData,"===================")
                      
                            // Now generate the Excel file using `xlsx` library
                            const ws = XLSX.utils.json_to_sheet(allData);
                            const wb = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(wb, ws, 'Data');
                      
                            // Export the file
                            XLSX.writeFile(wb, 'data.xlsx');
                            setloadingbutton(false)
                          } catch (error) {
                            setError('Error exporting data');
                            setloadingbutton(false)

                          } finally {
                            setLoading(false);
                            setloadingbutton(false)

                          }
                        };
        
        const handleClose = () => setShowModal(false);
    


    const user_id = useSelector((state)=> state.user.currentUser)
    const handleSelectChanges = (event) => {
        const value = event.target.value;
        setSelectedOptions(value);
        if (value === 'Loan Date' || value === 'Due Date') {
          setOpenDate(false);
        } else {
          setOpenDate(true);
        }
      };
      const toggleCalendar = () => {
        setShowCalendar(!showCalendar); // Toggle the calendar visibility
      };
    
  const handleSelects= (ranges) => {
    setSelectionRange(ranges.selection); // Update the selectionRange state with new date range

  };
  const handlAgencySelectChange = (event)=>{
    setAgencySelection(event.target.value)
    fetchData()

  }
  const handleCloseModal2 = () => {
    setShowModal(false); // Close the modal when needed
    setSelectedLoanId(null); // Reset the selected loan ID
  };
  const handleLoanClick1 = (loanId) => {
    setCog_id(loanId); // Capture the clicked loan's id
    setShowModal1(true);        // Show the modal
  };


  const handleCloseModal1 = () => {
    setShowModal1(false); // Close the modal when needed
    setSelectedLoanId(null); // Reset the selected loan ID
  };
  const handleSave = async(loanId, remarks, remarksStatus) => {
    const token = localStorage.getItem('access_token')
    // Perform your save action here...
    const updateResponse = await fetch(`${config.apiUrl}update_remark`, {
      method: 'PUT',
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        loanId,
        remarks,
        remarksStatus,
        agent_id: user_id._id
      }),
    });
    
  };
  const handleLoanClick = (loan_no,loanId) => {
    console.log(loanId,loan_no,"====================")

    setSelectedLoanId(loanId); // Capture the clicked loan's id
    setLoan_no(loan_no)
    setShowModal(true);        // Show the modal
  };
  const handleCloseModal = () => {
    setstart('')
    setend('')
    setShowCustomRange(false);
  };
  const handleApply = () => {
    setShowCalendar(false); // Close the calendar after applying the date range
  };
  const handleChanges = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value.trim() });
  };
  const handleClick = async(e)=>{
    let value = e.target.value
    setSelection(e.target.value)
    setSelectedOption(e.target.value)
    if (value === 'custom') {
        setShowCustomRange(true); // Show custom range input fields
      } else {
        setShowCustomRange(false); // Hide custom range input fields
      }
  }

 
    async function fetchData(){
      const token = localStorage.getItem('access_token')
        const Respones = await fetch(`${config.apiUrl}mapped_collection/${user_id._id}?${selectedOptions}=${formData.LoanId}&account_type=${formData.accountType}&${selectedOptions}start=${new Date(selectionRange.startDate).toLocaleDateString()}&${selectedOptions}end=${new Date(selectionRange.endDate).toLocaleDateString()}&${selectedOptions}=${AgencySelection}&slab=${selectedOption}&startslab=${start}&endslab=${end}&loanMin=${formData.loanMin}&loanMax=${formData.loanMax}&pages=${currentPage}`,{
          method: 'GET',
          headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
        })
            const agency = await fetch(`${config.apiUrl}agent/${current_user}`,{
              method: 'GET',
              headers: {
                'Authorization': token,  // Replace with your actual token
                'Content-Type': 'application/json'  // Include this if you're sending JSON data
              },
            })
       
               const json_datas = await agency.json()
               setAgency(json_datas.data)
               if(loan != 'undefined' && loan.length > 0){
                const loan_data = await fetch(`${config.apiUrl}get_waiver/${loan}?percInterest=${Number(dailySelect.current.value)}&serperInterest=${Number(serviceSelect?.current?.value)}`,{
                  method: 'GET',
                  headers: {
                    'Authorization': token,  // Replace with your actual token
                    'Content-Type': 'application/json'  // Include this if you're sending JSON data
                  },
                })
            const json_data = await loan_data.json()
            console.log(percentage,json_data.data1)

            console.log(json_data)
            setloanData(json_data.data)
            setPercentage(json_data?.data1)
    
    }
        const json_data = await Respones.json()
        setData(json_data.data)
        setTotalPages(json_data.totalPages)

        
    }
    const handlecashfree = (event) =>{
        fetchData()
        const serviceValue = serviceSelect.current.value;
        const dailyValue = dailySelect.current.value;

    }
    const PAGE_LIMIT = 5; // Number of pages to show before and after current page

    const handlePageClick = (page) => {
      if (page < 1 || page > totalPages) return;
      setCurrentPage(page)
      fetchData();
    };
    
    const getPageNumbers = () => {
        const pages = [];
        const leftLimit = Math.max(1, currentPage - Math.floor(PAGE_LIMIT / 2));
        const rightLimit = Math.min(totalPages, currentPage + Math.floor(PAGE_LIMIT / 2));
    
        if (leftLimit > 1) {
            pages.push(1);
            if (leftLimit > 2) pages.push('...'); // Ellipsis for skipped pages
        }
    
        for (let i = leftLimit; i <= rightLimit; i++) {
            pages.push(i);
        }
    
        if (rightLimit < totalPages) {
            if (rightLimit < totalPages - 1) pages.push('...'); 
            pages.push(totalPages);
        }
    
        return pages;
    };
   const  handlecashfreelink = async(loan_amount, loan_id)=>{
    const token = localStorage.getItem('access_token')
    let postData = {
        loan_id: loan_id,
        link_amount: loan_amount
    }
    const PAGE_LIMIT = 5; // Number of pages to show before and after current page


    const response = await fetch(`${config.apiUrl}cashfree_link`, {
        method: 'POST',               // Set the request method to POST
        headers: {
          'Authorization': token,  // Replace with your actual token
          'Content-Type': 'application/json'  // Include this if you're sending JSON data
        },
        body: JSON.stringify(postData) // Convert the data to a JSON string
    });
    }
    const handleFile = async (e)=>{
      const token = localStorage.getItem('access_token')
        let Respones = await fetch(`${config.apiUrl}mapped_collection/${user_id._id}?excel=true`,{
          method: 'GET',
          headers: {
            'Authorization': token,  // Replace with your actual token
            'Content-Type': 'application/json'  // Include this if you're sending JSON data
          },
        })
        const json_data = await Respones.json()

        const binaryString = window.atob(json_data.body);  
        const byteArray = new Uint8Array(binaryString.length); 
        for (let i = 0; i < binaryString.length; i++) {
          byteArray[i] = binaryString.charCodeAt(i);
        }
    
        const blob = new Blob([byteArray], { type: 'application/octet-stream' }); 
        const url = window.URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = 'loan_mapping.xlsx'; 
        link.click();
    
        window.URL.revokeObjectURL(url);



    }

    useEffect(()=>{
        fetchData()
    },[selection,currentPage,totalPages,showCustomRange,selectedOptions,startDate,endDate,selectedOption,AgencySelection,selectionRange,loan])
  return (
    <> 
     <div className="card-header d-flex flex-wrap align-items-center justify-content-between gap-3">

<div className="d-flex flex-wrap gap-4">
  {/* <div className="mb-2">
  </div> */}

  <div className="mb-2">
    <label className="form-label" htmlFor="LoanId">
      Select search
    </label>
    <select
      className="form-select w-auto"
      id="grid-state"
      value={selectedOptions}
      onChange={handleSelectChanges}
    >
      <option value="" disabled>---Select----</option>
      <option value="LoanDate">Loan Date</option>
      <option value="DueDate">Due Date</option>
      <option value="State">State</option>
      <option value="Agency">Agency</option>
      <option value="Mobile_Number">Mobile</option>
      <option value="Loan_Id">Loan Id</option>
    </select>
  </div>

  <div className="mb-2">
    <label className="form-label" htmlFor="LoanId">
      {selectedOptions || "Enter here"}
    </label>

    {selectedOptions === 'LoanDate' || selectedOptions === 'DueDate' ? (
      <>
      

        {openDate && (
    <div style={{ position: "relative", width: "300px" }}>
      <button
        onClick={toggleCalendar}
        style={{
          padding: "7px 6px",
          border: "1px solid #ccc", // Add border to the button
          borderRadius: "5px",
          width: "70%",
          textAlign: "left", // Align text to the left for a cleaner look
          backgroundColor: "#fff",
          cursor: "pointer",
        }}
      >
        {selectionRange.startDate && selectionRange.endDate
          ? `${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`
          : "Select Date Range"
        }
      </button>

      {showCalendar && (
        <div
          style={{
            position: "absolute",
            top: "50px", // Adjust this to position the calendar
            left: "0",
            zIndex: 999,
            backgroundColor: "#fff", // Add a background color to make it more visible
            padding: "10px",
            border: "1px solid #ccc", // Add border for better separation
            borderRadius: "5px",
          }}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelects}
            showDateDisplay={false} // Optional: hides date display
          />
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            <button
              onClick={handleApply}
              style={{
                padding: "5px 7px",
                border: "1px solid #007BFF",
                borderRadius: "5px",
                backgroundColor: "#007BFF",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </div>         
        )}
      </>
    ) : selectedOptions === 'Agency' ? (
      <select
        className="form-select w-auto"
        id="AgencyId"
        onChange={handlAgencySelectChange}
        value={AgencySelection}
        
      >
                <option value="" disabled>Select Agency</option>

                {agency?.map((data,index)=>(
                <option key={index} value={data._id}>{data.username}</option>

    ))}
      </select>
    ) : selectedOptions === 'State' ? (
      <select
        className="form-select w-auto"
        id="AgencyId"
        onChange={handlAgencySelectChange}
        value={AgencySelection}
      >
        <option value="" disabled>Select State</option>
        <option value="Andhra Pradesh">Andhra Pradesh</option>
        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
        <option value="Assam">Assam</option>
        <option value="Bihar">Bihar</option>
        <option value="Chhattisgarh">Chhattisgarh</option>
        <option value="Goa">Goa</option>
        <option value="Gujarat">Gujarat</option>
        <option value="Haryana">Haryana</option>
        <option value="Himachal Pradesh">Himachal Pradesh</option>
        <option value="Jharkhand">Jharkhand</option>
        <option value="Karnataka">Karnataka</option>
        <option value="Kerala">Kerala</option>
        <option value="Maharashtra">Maharashtra</option>
        <option value="Madhya Pradesh">Madhya Pradesh</option>
        <option value="Manipur">Manipur</option>
        <option value="Meghalaya">Meghalaya</option>
        <option value="Mizoram">Mizoram</option>
        <option value="Nagaland">Nagaland</option>
        <option value="Odisha">Odisha</option>
        <option value="Punjab">Punjab</option>
        <option value="Rajasthan">Rajasthan</option>
        <option value="Sikkim">Sikkim</option>
        <option value="Tamil Nadu">Tamil Nadu</option>
        <option value="Tripura">Tripura</option>
        <option value="Telangana">Telangana</option>
        <option value="Uttar Pradesh">Uttar Pradesh</option>
        <option value="Uttarakhand">Uttarakhand</option>
        <option value="West Bengal">West Bengal</option>
        <option value="Agency3" disabled>-----UT-----</option>
        <option value="Andaman & Nicobar">Andaman & Nicobar</option>
        <option value="Dadra & Nagar Haveli">Dadra & Nagar Haveli</option>
        <option value="Daman & Diu"> Daman & Diu</option>
        <option value="Jammu & Kashmir">Jammu & Kashmir</option>
        <option value="Ladakh">Ladakh</option>
        <option value="Lakshadweep">Lakshadweep</option>
        <option value="Puducherry">Puducherry</option>
      </select>
    ) : (
      <input
        className="form-control w-auto"
        id="LoanId"
        type="text"
        placeholder="Search here"
        onChange={handleChanges}
      />
    )}

  </div>


  <div className='d-flex flex-wrap gap-2'>
  <button
  onClick={fetchData}
  className="btn btn-primary"
  style={{ height: '50px', width: '120px', marginTop: '30px' }}
>
  Search
</button>
  </div>

</div>



<div className="d-flex flex-wrap align-items-center gap-3">
    
<div className="mb-2">
    <label className="form-label" htmlFor="LoanId">
      No. of days slab
    </label>
    <div className="relative">
    <select
    id="status"
    className="form-select form-select-sm w-auto"
    defaultValue="Select Defaulted Loans"
    onChange={handleClick}
    style={{ maxWidth: '200px' }}  // Limit the width of the select element
  >
    <option value="Select Defaulted Loans" disabled>
      Select Defaulted Loans
    </option>
    <option value="1-30">1-30 days</option>
    <option value="31-60">31-60 days</option>
    <option value="61-90">61-90 days</option>
    <option value="91-120">91-120 days</option>
    <option value="121-180">121-180 days</option>
    <option value="181-365">181-365 days</option>
    <option value="365">+365 days</option>
    <option value="custom">Custom Range</option>
    <option value="All">All</option>
  </select>
    </div>
    {showCustomRange && (
        <div className="modal show" tabIndex="-1" style={{ display: 'block' }} aria-labelledby="customRangeModalLabel">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="customRangeModalLabel">Select Custom Slab Range</h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column gap-2">
                  <div className="mb-1">
                    <label htmlFor="start-date" className="form-label form-label-sm">Start Date</label>
                    <input
                      type="number"
                      id="start-date"
                      value={start}
                      onChange={(e) => setstart(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ width: '45%' }}
                      placeholder="Start"
                    />
                  </div>
                  <div className="mb-1">
                    <label htmlFor="end-date" className="form-label form-label-sm">End Date</label>
                    <input
                      type="number"
                      id="end-date"
                      value={end}
                      onChange={(e) => setend(e.target.value)}
                      className="form-control form-control-sm"
                      style={{ width: '45%' }}
                      placeholder="End"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                <button type="button" className="btn btn-primary" onClick={() => { /* Save custom range logic */ handleCloseModal(); }}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

  </div>

  {/* <div className="mb-2">
    <label className="form-label" htmlFor="LoanId">
      Min Loan Amount
    </label>
    <input
      className="form-control w-auto"
      id="loanMin"
      type="text"
      placeholder="Min Loan Amount"
      onChange={handleChanges}
    />
  </div>

  <div className="mb-2">
    <label className="form-label" htmlFor="LoanId">
      Max Loan Amount
    </label>
    <input
      className="form-control w-auto"
      id="loanMax"
      type="text"
      placeholder="Max Loan Amount"
      onChange={handleChanges}
    />
  </div>

  <div className="d-flex mt-7 gap-4">
    <button
      onClick={fetchData}
      className="btn btn-primary"
    >
      Search
    </button>
  </div> */}


</div>
<div className="d-flex flex-wrap align-items-center gap-3">
{individual?.loan_details?.export_access === true && (
      <button className="btn btn-sm btn-primary-600" onClick={handleExport}>
              {loadingbutton ? (
              <span style={loadingTextStyle}>
              <div style={spinnerStyle}></div>
              downloading...
            </span>

        ):(   
          <>     
          <i className="ri-add-line" />  
          Download Excel
          </> 
        )}
      </button>
    )}
</div>
</div>

<div className="card-body">
<div style={{ overflowX: 'auto', maxHeight: '750px', overflowY: 'auto' }}>
<table className="table bordered-table mb-0">
    <thead>
        <tr>
        <th scope="col">LoanDate</th>
        <th scope="col">Loan Id</th>

            <th scope="col">Agent Name            </th>
            <th scope="col">Name</th>
            <th scope="col">Account Type</th>
            <th scope="col">Mobile Number</th>

            <th scope="col">Loan Amount</th>
            <th scope="col">College Name</th>
            <th scope="col">Close Count(loan)</th>

            <th scope="col">Past Due day</th>
            <th scope="col">Total Service charge</th>
            <th scope="col">Total Daily Late Fee</th>
            <th scope="col">Total Penalty charges</th>
            <th scope="col">Total Payable Amount</th>
            <th scope="col">Amount due</th>
            <th scope="col">Due Date</th>
            <th scope="col">State code</th>
            <th scope="col">Pan Card</th>

            <th scope="col">Offer(25%)</th>
            <th scope="col">Offer(50%)</th>
            <th scope="col">Offer(65%)</th>
            <th scope="col">Offer(80%)</th>
            <th scope="col">Offer(100%)</th>


            <th scope="col">Address</th>

            <th scope="col">Generate Link</th>


            <th scope="col">Action</th>
        </tr>
    </thead>
    <tbody>
    {data?.map((data,index)=>{

const linkDate = new Date(data.link_date);  // Replace with the correct field
const currentDate = new Date();
const isLessThan24Hours = (currentDate - linkDate) < (24 * 60 * 60 * 1000);  // Less than 24 hours

// Apply light blue color if the condition is met
const rowClass = isLessThan24Hours ? 'bg-info' : 'bg-info';  // 'bg-info' is a light blue background in Bootstrap
return (

  <tr key={index} className={rowClass}> 
<td>{(new Date(data.start).toISOString().split('T')[0])}</td>

<td>
<Link to="#" className="text-primary-600">
{data.loan_id}
</Link>
</td>
<td>
<Link to="#" className="text-primary-600">
{data.Agent_name}
</Link>
</td>
<td>
{data.student.name}
</td>
<td>
<Link to="#" className="text-primary-600">
{data.user_acc_type}
</Link>
</td>
<td>
<td>
{data.student.mobile}
</td>

</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.loan_amt}
</h6>
</div>

</td>
<td>
{data.college_name}
</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.loan_count}
</h6>
</div>

</td>
<td>
<div className="d-flex align-items-center">

<h6 className="text-md mb-0 fw-medium flex-grow-1">
    {data.defaulted}
</h6>
</div>

</td>
<td>{data.serviceChargeFee}</td>
<td>{data.dailyLateFee}</td>
<td>{data.totalPenalty}</td>
<td>{data.totalAmountPayable}</td>
<td>{data.totalAmountPayable}</td>
<td>{(new Date(data.due).toISOString().split('T')[0])}</td>
<td>{data.state_code}</td>
<td>{data.pan_number}</td>

<td>{data.percentage['25%'].waived_total_amount}</td>

<td>{data.percentage['50%'].waived_total_amount}</td>
<td>{data.percentage['65%'].waived_total_amount}</td>
<td>{data.percentage['80%'].waived_total_amount}</td>

<td>{data.percentage['100%'].waived_total_amount}</td>


<td>
{" "}
<span className="bg-success-focus text-success-main px-24 py-4 rounded-pill fw-medium text-sm">
{data.Address}
</span>
</td>
<td>
{" "}
<span className="bg-red-focus text-red px-24 py-4 rounded-pill fw-medium text-sm">
<Icon              data-bs-toggle="modal"
        data-bs-target="#exampleModal"
icon="cil:paper-plane" style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => {
setLoan(String(data.loan_id))
}} />
</span>
</td>
<td>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-primary-light text-primary-600 rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="iconamoon:eye-light"
onClick={() => handleLoanClick1(data.student_cog_id)}

/>
</Link>
<Link
to="#"
className="w-32-px h-32-px  me-8 bg-success-focus text-success-main rounded-circle d-inline-flex align-items-center justify-content-center"
>
<Icon icon="lucide:edit"
onClick={() => handleLoanClick(data?.loan_id, data?._id)}

/>
</Link>

</td>
</tr>
)
})}

      
    </tbody>
</table>

</div>
<div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-24">
            <span>
                Showing {(currentPage - 1) * 10 + 1} to {Math.min(currentPage * 10, totalPages * 10)} of {totalPages * 10} entries
            </span>
            <ul className="pagination d-flex flex-wrap align-items-center gap-2 justify-content-center">
                {/* Previous Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === 1 ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <Icon icon="ep:d-arrow-left" className="text-xl" />
                    </Link>
                </li>

                {/* Page Numbers */}
                {getPageNumbers().map((page, index) => (
                    <li key={index} className="page-item">
                        {/* Render page number or ellipsis */}
                        <Link
                            className={`page-link fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${
                                page === currentPage ? 'bg-primary-600 text-white' : 'bg-primary-50 text-secondary-light'
                            }`}
                            to="#"
                            onClick={() => page !== '...' && handlePageClick(page)}
                        >
                            {page}
                        </Link>
                    </li>
                ))}

                {/* Next Button */}
                <li className="page-item">
                    <Link
                        className={`page-link text-secondary-light fw-medium radius-4 border-0 px-10 py-10 d-flex align-items-center justify-content-center h-32-px me-8 w-32-px ${currentPage === totalPages ? 'bg-base' : ''}`}
                        to="#"
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <Icon icon="ep:d-arrow-right" className="text-xl" />
                    </Link>
                </li>
            </ul>
        </div>


</div>
<div
className="modal fade"
id="exampleModal"
tabIndex={-1}
aria-labelledby="exampleModalLabel"
aria-hidden="true"
>
<div className="modal-dialog modal-lg modal-dialog modal-dialog-centered">
    <div className="modal-content radius-16 bg-base">
        <div className="modal-header py-16 px-24 border border-top-0 border-start-0 border-end-0">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
                Generate Link
            </h1>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
            />
        </div>
        <div className="modal-body p-24">
            <form action="#">
            <div className="d-flex flex-wrap align-items-center gap-3 mb-5">
            <p className="fw-semibold text-gray-500">Service  %:</p>
  <select
    id="service"
    className="form-select form-select-sm w-auto"
    defaultValue="service"
    ref={serviceSelect}
    onChange={handlecashfree}
  >
    <option value="service" disabled>
      Select Service charge discount
    </option>
    {percentage
      ?.find(item => item?.type === 'service_fee')
      ?.values.map((value, index) => (
        <option key={index} value={value}>
          {value}%
        </option>
      ))}
  </select>


    <p className="fw-semibold text-gray-500">Daily  %:</p>

    <select
  id="daily"
  className="form-select form-select-sm w-auto"
  defaultValue="daily"
  ref={dailySelect}
  onChange={handlecashfree}
>
  <option value="daily" disabled>
    Select Defaulted Loans
  </option>
  {percentage
    ?.find(item => item?.type === 'daily_late')
    ?.values.map((value, index) => (
      <option key={index} value={value}>
        {value}%
      </option>
    ))}
</select>
    </div>
                <div className="row">
                <div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Defaulting in days:</p>
<p className="text-right text-gray-600">{loan_data?.no_of_days_past_due}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Service Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.serviceChargeFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Daily Charge Fee:</p>
<p className="text-right text-gray-600">{loan_data?.dailyLateFee}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Penalty:</p>
<p className="text-right text-gray-600">{loan_data?.totalPenalty}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount Payable:</p>
<p className="text-right text-gray-600">{loan_data?.totalAmountPayable}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Service Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.service_waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Daily Charge Payable:</p>
<p className="text-right text-gray-600">{loan_data?.waived}</p>
</div>
<div className="d-flex align-items-center justify-content-between border border-gray-300 bg-gray-50 p-2 rounded shadow-sm">
<p className="fw-semibold text-gray-500">Total Amount to be generated:</p>
<p className="text-right text-gray-600">{loan_data?.waived_total_amount}</p>
</div>

                    <div className="d-flex align-items-center justify-content-center gap-3 mt-24">
                        <button
                            type="reset"
                            className="border border-danger-600 bg-hover-danger-200 text-danger-600 text-md px-40 py-11 radius-8"
                       data-bs-dismiss="modal"
                      aria-label="Close"
                      >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary border border-primary-600 text-md px-48 py-12 radius-8"
                            onClick={()=> handlecashfreelink(loan_data?.waived_total_amount, loan_data.loan_id)}
                        >
                            Generate
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</div>
{showModal && (
    <ModalEditRemarks
    show={showModal}
    handleClose={handleCloseModal2}
    handleSave={handleSave}
    loanId ={loan_no}

    loan_no={selectedLoanId} // Pass the selected loan ID to the modal
  />
      )}

{showModal1 && (
        <ModalLoan
          show={showModal1}
          handleClose={handleCloseModal1}
          CogID={cog_id} // Pass the selected loan ID to the modal
        />
      )}
    </>  
    
)
}

export default AgencyForm1