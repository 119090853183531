import React from 'react'
import MasterLayout from '../masterLayout/MasterLayout'
import { Breadcrumb } from 'react-bootstrap'
import AddAgentList from '../components/child/AddAgentList'
import Offer from '../components/child/Offer'

const Offers = () => {
  return (
    <MasterLayout>

    {/* Breadcrumb */}
    <Breadcrumb title="Offers" />

<Offer/>

  </MasterLayout>   )
}

export default Offers